import { useEffect, useMemo, useState } from 'react';

import moment from 'moment';
import { Form, FormProvider, useForm } from 'react-hook-form';

import { Chart } from 'react-chartjs-2';
import ReactSlider from 'react-slider';
import {
  CropToCode,
  MonthToCode,
  cropToMonthOptions,
  yearOptions,
} from '../../../constants';
import { optionsChartOptions } from '../../../constants/chartConfigs';
import {
  optionFilters,
  optionTypeOptions,
} from '../../../constants/selectCommonConfigs';
import { useWebsocketContext } from '../../../contexts/websocket-context';
import { usePrices } from '../../../hooks/market/use-futures';
import { useOptions, useStrikePrices } from '../../../hooks/market/use-options';
import { useWebSocketDataV2 } from '../../../hooks/websocket/use-websocket-data';
import {
  capitalizeFirst,
  constructOptionsSymbol,
  getTime,
  parseOptionsContract,
} from '../../../utils';
import Select from '../../commons/Select';
import Table, { TableConfigs } from '../../commons/table/Table';
import TableLayout from '../commons/TableLayout';
import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/24/solid';
import CardHeader from '../../commons/CardHeader';
import { useControlledUser } from '../../../contexts/auth-context';
import CropIcon from '../../commons/CropIcon';

function OptionsTable() {
  const optionsTableConfigs: TableConfigs = {
    cols: [
      {
        key: 'contract',
        name: 'Contract',
      },
      {
        key: 'optionDetails',
        name: 'Option',
        type: 'element',
        element: (item: any) => {
          return (
            <div className="flex">
              <span className="flex gap-2">
                {capitalizeFirst(item.optionType)} &nbsp;
                {item.strike}
              </span>
            </div>
          );
        },
      },
      {
        key: 'low',
        name: 'Low',
      },
      {
        key: 'high',
        name: 'High',
      },
      {
        key: 'last',
        name: 'Last',
      },
      {
        key: 'change',
        name: 'Change',
        type: 'fluctuation',
      },
      // {
      //   key: 'bid',
      //   name: 'Bid',
      // },
      // {
      //   key: 'ask',
      //   name: 'Ask',
      // },
      {
        key: 'valuation',
        name: 'AgriVaR Valuation',
      },
      {
        key: 'volume',
        name: 'Volume',
      },
      {
        key: 'openInterest',
        name: 'Open Interest',
      },
      {
        key: 'IV',
        name: 'IV',
      },
      {
        key: 'delta',
        name: 'Delta',
      },
      {
        key: 'lastTradeTime',
        name: 'Last Trade',
      },
    ],
  };

  const [sliderValues, setSliderValues] = useState<[number, number]>([0, 0]);

  const methods = useForm();
  const user = useControlledUser();
  const crop = methods.watch('crop', 'Corn');
  const month = methods.watch('month', 'december');
  const year = methods.watch('year', '2024');
  const strikePrice = methods.watch('strikePrice', '0000');
  const optionType = methods.watch('optionType', 'put');

  const underlying = useMemo(() => {
    return CropToCode[crop] + MonthToCode[month] + year.slice(3);
  }, [crop, month, year]);

  const options = useOptions(underlying, '');

  const strikePrices = useStrikePrices(underlying);

  const optionsSymbol = useMemo(() => {
    return constructOptionsSymbol(
      CropToCode[crop],
      month,
      year,
      optionType,
      strikePrice
    );
  }, [crop, month, year, strikePrice, optionType]);

  const [cachedOptionsSymbol, setCachedOptionsSymbol] = useState('');

  const currentContractDetails = useMemo(() => {
    const optionsContract = options?.data?.data?.find((item: any) => {
      return item.symbol === optionsSymbol;
    });
    console.log('change', optionsContract?.change, typeof optionsContract?.change);
    if (typeof optionsContract?.change === 'string') {
      optionsContract.change = parseFloat(optionsContract.change).toFixed(3).toString();
    }
    return {
      bid: optionsContract?.bid,
      ask: optionsContract?.ask,
      change: optionsContract?.change,
      last: optionsContract?.last,
      symbol: optionsContract?.symbol,
      last_trade_time: optionsContract?.last_trade_time,
    };
  }, [optionsSymbol, options.data?.data]);

  const { setRoots } = useWebsocketContext();

  const optionsHLCO = usePrices(optionsSymbol);
  const msgHLCO: any = useWebSocketDataV2('HLCO');
  const msgBidAsk: any = useWebSocketDataV2('Bid_Ask');
  const msgHedges: any = useWebSocketDataV2('Hedges');

  const [updatedContract, setUpdatedContract] = useState<any>(null);

  useEffect(() => {
    if (
      msgBidAsk &&
      msgBidAsk.data?.symbol === currentContractDetails.symbol &&
      (!currentContractDetails.last_trade_time ||
        msgBidAsk.data?.last_trade_time >
          currentContractDetails.last_trade_time)
    ) {
      if (typeof msgBidAsk.data?.change === 'string') {
        msgBidAsk.data.change = parseFloat(msgBidAsk.data?.change).toFixed(3).toString();
      }
      const tmpUpdateContract = {
        ...currentContractDetails,
        bid: msgBidAsk.data?.bid
          ? msgBidAsk.data?.bid
          : currentContractDetails.bid,
        ask: msgBidAsk.data?.ask
          ? msgBidAsk.data?.ask
          : currentContractDetails.ask,
        change: msgBidAsk.data?.change,
        last: msgBidAsk.data?.trade_price,
        last_trade_time: msgBidAsk.data?.last_trade_time,
      };
      setUpdatedContract(tmpUpdateContract);
    } else {
      setUpdatedContract(currentContractDetails);
    }
  }, [msgBidAsk.data, currentContractDetails]);

  const [optionsTableFilter, setOptionsTableFilter] = useState('all');

  const [loadedTableData, setLoadedTableData] = useState(false);
  const [tableData, setTableData] = useState<any[]>([]);
  const [highlightedCells, setHighlightedCells] = useState<
    { rowKey: string; columnKey: string }[]
  >([]);

  const commodityOptions = [
    { value: 'Corn', label: 'Corn', icon: <CropIcon cropName="Corn" /> },
    {
      value: 'HRW Wheat',
      label: 'HRW Wheat',
      icon: <CropIcon cropName="Wheat" />,
    },
    {
      value: 'Soybeans',
      label: 'Soybeans',
      icon: <CropIcon cropName="Soybeans" />,
    },
  ];

  function calculateOptionStatus(
    marketPrice: number,
    strikePrice: number,
    optionType: string,
    tolerancePercent: number = 5
  ): String {
    const tolerance = tolerancePercent / 100;
    const lowerBound = strikePrice - 0.1
    const upperBound = strikePrice + 0.1

    if (optionType === 'call') {
      if (marketPrice > strikePrice) {
        return 'ITM';
      } else if (marketPrice < lowerBound) {
        return 'OTM';
      } else {
        return 'NTM';
      }
    } else if (optionType === 'put') {
      console.log('market price , strik price', marketPrice, strikePrice, upperBound, typeof marketPrice, typeof strikePrice);
      if (marketPrice < strikePrice) {
        return 'ITM';
      } else if (marketPrice > upperBound) {
        console.log('put OTM')
        return 'OTM';
      } else {
        return 'NTM';
      }
    }

    throw new Error("Invalid option type. Must be 'call' or 'put'.");
  }

  useEffect(() => {
    const newHighlights: { rowKey: string; columnKey: string }[] = [];
    let mapData: any[] = [];

    if (tableData && tableData.length > 0) {
      if (cachedOptionsSymbol && cachedOptionsSymbol !== optionsSymbol) {
        mapData = options.data?.data; // Initialize with options hook data
        setCachedOptionsSymbol(optionsSymbol);
      } else {
        mapData = tableData; // Use the existing tableData
        setCachedOptionsSymbol(optionsSymbol);
      }
    } else {
      mapData = options.data?.data; // Initialize with options hook data
      setLoadedTableData(true); // Mark as loaded
    }
    const updatedData =
      mapData
        ?.map((item: any) => {
          if (item && item.symbol) {
            let newHLCO = {};
            let newBidAsk = {};
            let newHedges = {};
            let isNewMessage = false;
            let columnUpdated = false;

            const parsedContract = parseOptionsContract(item.symbol);
            const contract =
              parsedContract.commodity +
              ', ' +
              parsedContract.month +
              " '" +
              parsedContract.year;

            const optionType = parsedContract.optionType;
            const strikePrice = parsedContract.strikePrice;
            const optionDetails = `${capitalizeFirst(optionType)} ${strikePrice}`;

            if (msgHLCO && msgHLCO.data.symbol === item.symbol) {
              newHLCO = msgHLCO.data;
              isNewMessage = true; // Only trigger highlight on HLCO message
              if (
                item.high !== msgHLCO.data.high &&
                msgHLCO.data.high !== undefined
              ) {
                columnUpdated = true;
                newHighlights.push({
                  rowKey: optionDetails,
                  columnKey: 'high',
                });
              }
              if (
                item.low !== msgHLCO.data.low &&
                msgHLCO.data.low !== undefined
              ) {
                columnUpdated = true;
                newHighlights.push({ rowKey: optionDetails, columnKey: 'low' });
              }
              if (
                item.last !== msgHLCO.data.last &&
                msgHLCO.data.last !== undefined
              ) {
                columnUpdated = true;
                newHighlights.push({
                  rowKey: optionDetails,
                  columnKey: 'last',
                });
              }
              if (
                item.change !== msgHLCO.data.change &&
                msgHLCO.data.change !== undefined
              ) {
                columnUpdated = true;
                newHighlights.push({
                  rowKey: optionDetails,
                  columnKey: 'change',
                });
              }
            }
            if (msgBidAsk && msgBidAsk.data.symbol === item.symbol) {
              newBidAsk = msgBidAsk.data;
              isNewMessage = true; // Trigger highlight on BidAsk only if no HLCO for this symbol
              if (
                item.bid !== msgBidAsk.data.bid &&
                msgBidAsk.data.bid !== undefined
              ) {
                columnUpdated = true;
                newHighlights.push({ rowKey: optionDetails, columnKey: 'bid' });
              }
              if (
                item.change !== msgBidAsk.data.change &&
                msgBidAsk.data.change !== undefined
              ) {
                columnUpdated = true;
                newHighlights.push({
                  rowKey: optionDetails,
                  columnKey: 'change',
                });
              }
              if (
                item.volume !== msgBidAsk.data.volume &&
                msgBidAsk.data.volume !== undefined
              ) {
                columnUpdated = true;
                newHighlights.push({
                  rowKey: optionDetails,
                  columnKey: 'volume',
                });
              }
              if (
                item.openInterest !== msgBidAsk.data.open_interest &&
                msgBidAsk.data.open_interest !== undefined
              ) {
                columnUpdated = true;
                item.openInterest = msgBidAsk.data.open_interest;
                newHighlights.push({
                  rowKey: optionDetails,
                  columnKey: 'openInterest',
                });
              }
              if (
                item.last !== msgBidAsk.data.trade_pice &&
                msgBidAsk.data.trade_price !== undefined
              ) {
                columnUpdated = true;
                item.last = msgBidAsk.data.trade_price;
                newHighlights.push({
                  rowKey: optionDetails,
                  columnKey: 'last',
                });
              }
              if (
                item.IV !== msgBidAsk.data.implied_volatility &&
                msgBidAsk.data.implied_volatility !== undefined
              ) {
                item.IV = msgBidAsk.data.implied_volatility;
                columnUpdated = true;
                newHighlights.push({ rowKey: optionDetails, columnKey: 'IV' });
              }
              if (
                item.IV_change !== msgBidAsk.data.implied_volatility_chg &&
                msgBidAsk.data.implied_volatility_chg !== undefined
              ) {
                item.IV_change = msgBidAsk.data.implied_volatility_chg;
                columnUpdated = true;
                newHighlights.push({
                  rowKey: optionDetails,
                  columnKey: 'IV_change',
                });
              }
              if (
                item.ask !== msgBidAsk.data.ask &&
                msgBidAsk.data.ask !== undefined
              ) {
                columnUpdated = true;
                newHighlights.push({ rowKey: optionDetails, columnKey: 'ask' });
              }
            }
            if (msgHedges && msgHedges.data.symbol === item.symbol) {
              newHedges = msgHedges.data;
              isNewMessage = true; // Only trigger highlight on hedges message
              if (
                item.valuation !== msgHedges.data.valuation &&
                msgHedges.data.valuation !== undefined
              ) {
                columnUpdated = true;
                item.valuation = msgHedges.data.valuation;
                newHighlights.push({
                  rowKey: optionDetails,
                  columnKey: 'valuation',
                });
              }
            }

            const date = moment(
              `${month} 1, 20${year}`,
              'MMM D, YYYY'
            ).toISOString();

            const record = {
              ...item,
              ...newHLCO,
              ...newBidAsk,
              contract,
              optionType,
              strike: strikePrice,
              optionDetails,
              lastTradeTime: getTime(item.last_trade_time),
              money: calculateOptionStatus(
                parseFloat(item.last),
                parseFloat(strikePrice.slice(1)),
                optionType
              ),
            };
            record.change = parseFloat(record.change).toFixed(4);
            return record;
          }
          return null;
        })
        .filter((value: any) => value !== null && value !== undefined)
        .sort((a: any, b: any) => {
          if (a.contract < b.contract) {
            return -1;
          }
          if (a.contract > b.contract) {
            return 1;
          }
          const strikeA = parseFloat(a.strike.replace(/[^0-9.-]+/g, ''));
          const strikeB = parseFloat(b.strike.replace(/[^0-9.-]+/g, ''));
          // sort by strike
          if (strikeA < strikeB) {
            return 1;
          }
          if (strikeA > strikeB) {
            return -1;
          }
          return 0;
        }) ?? [];
    console.log('updatedData', updatedData);
    setTableData(updatedData);
    if (newHighlights.length > 0) {
      setHighlightedCells((prev) =>
        Array.from(new Set([...prev, ...newHighlights]))
      );
    }
  }, [
    options.data?.data,
    msgHLCO.data,
    msgBidAsk.data,
    msgHedges.data,
    optionsTableFilter,
  ]);

  useEffect(() => {}, [highlightedCells.length]);

  useEffect(() => {
    // Remove highlights after the specified duration
    const timeout = setInterval(() => {
      setHighlightedCells([]);
    }, 500);

    // Cleanup timeout if component unmounts or dependencies change
    return () => clearTimeout(timeout);
  }, []);

  const priceChartData = useMemo(() => {
    if (!optionsHLCO.data || !optionsHLCO.data.price) return [];
    return optionsHLCO.data.price.map((item: any) => {
      if (
        msgHLCO &&
        msgHLCO.data.symbol === item.symbol &&
        msgHLCO.data.trade_date === item.trade_date
      ) {
        return {
          x: new Date(item.trade_date).getTime(),
          o: msgHLCO.data?.open
            ? parseFloat(msgHLCO.data.open)
            : parseFloat(item.open),
          h: msgHLCO.data?.high
            ? parseFloat(msgHLCO.data.high)
            : parseFloat(item.high),
          l: msgHLCO.data?.low
            ? parseFloat(msgHLCO.data.low)
            : parseFloat(item.low),
          c: msgHLCO.data?.close
            ? parseFloat(msgHLCO.data.close)
            : parseFloat(item.close),
        };
      } else {
        return {
          x: new Date(item.trade_date).getTime(),
          o: parseFloat(item.open),
          h: parseFloat(item.high),
          l: parseFloat(item.low),
          c: parseFloat(item.close),
        };
      }
    });
  }, [optionsHLCO.data, msgHLCO.data]);


  const [dynamicCropToMonthOptions, setDynamicCropToMonthOptions] = useState<any>(cropToMonthOptions[crop as keyof typeof cropToMonthOptions]);

  useEffect(() => {
    // Adjust the month selection based on the current date and selected year to avoid expired contracts
    const selectedYear = year;
    const currentMonth = moment().month() + 1;
    const currentYear = moment().format('YYYY');
    const cropMonths = cropToMonthOptions[crop as keyof typeof cropToMonthOptions];
    const availableMonths = cropMonths.filter(month => {
      const monthDate = parseInt(moment(month.label, 'MMMM').format('M'));
      if (currentYear === selectedYear) {
        return monthDate >= currentMonth;
      } else {
        return true; // If the selected year is in the future, show all months
      }
    });
    setDynamicCropToMonthOptions(availableMonths);
    const lastMonthOption = availableMonths.length > 0 ? availableMonths[availableMonths.length - 1] : { value: 'december', label: 'December' };
    if (lastMonthOption) {
      methods.setValue('month', lastMonthOption.value);
    }
  }, [crop, year]);


  const filteredPriceChartData = useMemo(() => {
    return priceChartData.filter(
      (item: any) => item.x >= sliderValues[0] && item.x <= sliderValues[1]
    );
  }, [priceChartData, sliderValues]);

  const minDate = useMemo(() => {
    return priceChartData.length > 0
      ? Math.min(...priceChartData.map((item: any) => item.x))
      : 0;
  }, [priceChartData]);

  const maxDate = useMemo(() => {
    return priceChartData.length > 0
      ? Math.max(...priceChartData.map((item: any) => item.x))
      : 0;
  }, [priceChartData]);

  useEffect(() => {
    if (minDate && maxDate) {
      setSliderValues([minDate, maxDate]);
    }
  }, [minDate, maxDate]);

  useEffect(() => {
    setRoots([CropToCode[crop]]);
  }, [crop]);

  const data: any = {
    datasets: [
      {
        backgroundColors: {
          up: '#80bc00',
          down: '#eab208',
          unchanged: 'rgba(143, 143, 143, 1)',
        },
        borderColors: {
          up: '#80bc00',
          down: '#eab208',
          unchanged: 'rgba(143, 143, 143, 1)',
        },
        label: 'Options HLCO',
        data: filteredPriceChartData,
        type: 'candlestick',
        borderColor: '#00c0ef',
        backgroundColor: 'rgba(0, 192, 239, 0.2)',
      },
    ],
  };

  

  return (
    <>
      <FormProvider {...methods}>
        <Form>
          <p className="text-lg text-darkGray mb-2">Contract</p>
          <div className="flex items-center pb-6 justify-between text-lightGray rounded-md">
            <div className="flex items-center space-x-6">
              <div className="flex items-center space-x-2 rounded-md">
                <Select
                  options={commodityOptions}
                  name="crop"
                  textClassName="text-xl text-base-bold"
                />
              </div>
              <div className="flex items-center space-x-2 rounded-md">
                <Select
                  textClassName="text-xl"
                  name="month"
                  value={month}
                  options={
                    dynamicCropToMonthOptions
                  }
                  defaultValue={
                    dynamicCropToMonthOptions[crop as keyof typeof dynamicCropToMonthOptions] ? dynamicCropToMonthOptions[crop as keyof typeof dynamicCropToMonthOptions][
                      dynamicCropToMonthOptions[
                        crop as keyof typeof dynamicCropToMonthOptions
                      ]?.length - 1
                    ]?.value : 'december'
                  }
                  className="w-full text-base-bold"
                />
              </div>
              <div className="flex items-center space-x-2 rounded-md">
                <Select
                  textClassName="text-xl"
                  name="year"
                  options={yearOptions}
                  defaultValue={'2024'}
                  className="w-full text-base-bold"
                />
              </div>
              <div className="flex items-center space-x-2 rounded-md">
                {strikePrices &&
                  strikePrices?.data &&
                  strikePrices?.data.strikePrices[
                    optionType.slice(0, 1).toUpperCase() +
                      optionType.slice(1, optionType.length)
                  ] &&
                  strikePrices?.data.strikePrices[
                    optionType.slice(0, 1).toUpperCase() +
                      optionType.slice(1, optionType.length)
                  ].length > 0 && (
                    <Select
                      textClassName="text-xl text-base-bold"
                      name="strikePrice"
                      options={
                        strikePrices.data?.strikePrices[
                          optionType.slice(0, 1).toUpperCase() +
                            optionType.slice(1, optionType.length)
                        ]!
                      }
                    />
                  )}
              </div>
              <div className="flex items-center space-x-2 rounded-md">
                <Select
                  textClassName="text-xl"
                  name="optionType"
                  options={optionTypeOptions}
                  className="w-full text-base-bold"
                />
              </div>
            </div>
            <div className="flex items-center space-x-8">
              <div
                className={`${parseFloat(updatedContract?.change) > 0 ? 'text-primary' : parseFloat(updatedContract?.change) === 0 ? (user?.network_partner === 'Heartland' ? 'text-black' : 'text-white') : 'text-red'} flex items-center space-x-2`}
              >
                <span className="text-2xl text-base-bold">
                  ${updatedContract?.last ?? '0'}
                </span>
                <div className="flex items-center space-x-1">
                  {updatedContract?.change &&
                  parseFloat(updatedContract?.change) > 0 ? (
                    <ArrowUpIcon className="w-4 h-4" />
                  ) : (
                    <ArrowDownIcon className="w-4 h-4" />
                  )}
                  <span className="text-xl text-base-bold">
                    ${updatedContract?.change ?? '0'} (
                    {(
                      (100 * parseFloat(updatedContract?.change ?? '0')) /
                      parseFloat(updatedContract?.last ?? '1')
                    ).toFixed(2)}
                    %)
                  </span>
                </div>
              </div>
            </div>
          </div>
        </Form>
      </FormProvider>
      <div className="card relative overflow-hidden">
        <div className="flex flex-col mb-4">
          <div className="px-2">
            <Chart
              type="candlestick"
              data={data}
              options={optionsChartOptions(optionsSymbol)}
            />
          </div>

          <div className="w-full mb-4 pl-14 border-0 h-full">
            {priceChartData && priceChartData.length > 0 && (
              <div className="pr-2">
                <ReactSlider
                  className="horizontal-slider2"
                  thumbClassName="example-thumb2"
                  trackClassName="example-track2"
                  min={minDate}
                  max={maxDate}
                  defaultValue={[minDate, maxDate]}
                  value={sliderValues}
                  ariaValuetext={(state) =>
                    `Thumb value ${new Date(state.valueNow).toLocaleDateString()}`
                  }
                  renderThumb={(props, state) => <div {...props}>{}</div>}
                  pearling
                  minDistance={10}
                  onChange={(values) =>
                    setSliderValues(values as [number, number])
                  }
                />
                <div className="w-full h-full text-gray mt-10 flex items-center justify-center">
                  <span>{new Date(sliderValues[0]).toDateString()}</span>&nbsp;
                  - &nbsp;
                  <span>{new Date(sliderValues[1]).toDateString()}</span>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="card mt-4 relative overflow-hidden">
        <TableLayout
          icon={
            commodityOptions.filter((item: any) => crop === item.value)[0].icon
          }
          title={
            <>
              <Select
                textClassName="text-xl"
                name="optionFilter"
                options={optionFilters}
                onChange={(e) => setOptionsTableFilter(e.target.value)}
                className="text-base-bold"
              />
              <CardHeader
                title={`${crop} ${capitalizeFirst(month.slice(0,3))} '${year.slice(2,4)} Options`}
                className="whitespace-nowrap"
              />
            </>
          }
        >
          <Table
            configs={optionsTableConfigs}
            data={tableData
              .filter((item) => {
                if (optionsTableFilter === 'all') {
                  return true;
                } else if (optionsTableFilter === 'ITM') {
                  return item.money === 'ITM';
                } else if (optionsTableFilter === 'OTM') {
                  return item.money === 'OTM';
                } else if (optionsTableFilter === 'NTM') {
                  return item.money === 'NTM';
                } else {
                  return item.optionType === optionsTableFilter;
                }
              })
              .sort((a, b) => {
                // sort by strike
                const strikeA = parseFloat(a.strike.replace(/[^0-9.-]+/g, ''));
                const strikeB = parseFloat(b.strike.replace(/[^0-9.-]+/g, ''));
                if (strikeA < strikeB) {
                  return -1;
                }
                if (strikeA > strikeB) {
                  return 1;
                }
                if (a.optionType < b.optionType) {
                  return -1;
                } else if (a.optionType > b.optionType) {
                  return 1;
                }
                return 0;
              })}
            loading={options.isLoading || !loadedTableData}
            highlightCells={highlightedCells}
            indexKey="optionDetails"
            pagination={{
              size: 20,
            }}
          />
        </TableLayout>
      </div>
    </>
  );
}

export default OptionsTable;
