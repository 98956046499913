import {
  ChevronDoubleLeftIcon,
  ChevronLeftIcon,
  ChevronRightIcon,
  ChevronDoubleRightIcon,
} from '@heroicons/react/24/solid';
import clsx from 'clsx';
import IconButton from './inputs/IconButton';
import { useMemo, useState } from 'react';

interface PaginationProps {
  page: number;
  totalPage: number;
  setPage: (page: number) => void;
  onPrevPage: () => void;
  onNextPage: () => void;
}

export const usePagination = ({
  size,
  total,
  pageUpCallback,
  pageDownCallback
}: {
  size: number;
  total: number;
  pageUpCallback?: () => void;
  pageDownCallback?: () => void;
}) => {
  const [page, setPage] = useState(1);
  const pageSize = useMemo(() => size ?? 10, [size]);

  const skip = useMemo(() => (page - 1) * pageSize, [page, pageSize, size]);
  const endIndex = useMemo(() => page * pageSize, [page, pageSize, size]);
  const totalPage = useMemo(
    () => Math.ceil(total / pageSize),
    [total, pageSize, size]
  );

  const onPrevPage = () => {
    if (page > 1) {
      setPage((page) => page - 1);
      pageDownCallback && pageDownCallback();
    }
  };
  const onNextPage = () => {
    if (page < totalPage) {
      setPage((page) => page + 1);
      pageUpCallback && pageUpCallback();
    }
  };

  return {
    page,
    setPage,
    pageSize,
    skip,
    endIndex,
    totalPage,
    onPrevPage,
    onNextPage,
  };
};

export default function Pagination({
  page,
  totalPage,
  setPage,
  onPrevPage,
  onNextPage,
}: PaginationProps) {
  return (
    <nav className="flex space-x-2.5 justify-center w-full p-2">
      <IconButton onClick={() => setPage(1)}>
        <ChevronDoubleLeftIcon className="size-3" />
      </IconButton>
      <IconButton onClick={onPrevPage}>
        <ChevronLeftIcon className="size-3" />
      </IconButton>

      {/* Numbers */}
      {/* First page */}
      <IconButton
        className={clsx(
          'rounded !text-md-regular size-6 !p-0',
          page === 1 &&
            '!bg-none !bg-primary group-data-[theme="Heartland"]/root:text-base-000'
        )}
        onClick={() => setPage(1)}
      >
        1
      </IconButton>

      {/* Second page */}
      {totalPage > 1 && (page <= 3 || page === totalPage) && (
        <IconButton
          className={clsx(
            'rounded !text-md-regular size-6 !p-0',
            page === 2 &&
              '!bg-none !bg-primary group-data-[theme="Heartland"]/root:text-base-000'
          )}
          onClick={() => setPage(2)}
        >
          2
        </IconButton>
      )}

      {/* Third page */}
      {totalPage > 2 && (page <= 3 || page === totalPage) && (
        <IconButton
          className={clsx(
            'rounded !text-md-regular size-6 !p-0',
            page === 3 &&
              '!bg-none !bg-primary group-data-[theme="Heartland"]/root:text-base-000'
          )}
          onClick={() => setPage(3)}
        >
          3
        </IconButton>
      )}

      {/* Dots around page > 3 */}
      {totalPage > 4 && (
        <IconButton className={clsx('rounded !text-md-regular size-6 !p-0')}>
          ...
        </IconButton>
      )}

      {/* Current page if > 3 */}
      {totalPage > 3 && page > 3 && page < totalPage && (
        <IconButton
          className={clsx(
            'rounded !text-md-regular size-6 !p-0 group-data-[theme="Heartland"]/root:text-base-000',
            '!bg-none !bg-primary'
          )}
        >
          {page}
        </IconButton>
      )}

      {/* Dots around page > 3 */}
      {totalPage > 3 && page > 3 && page < totalPage && (
        <IconButton className={clsx('rounded !text-md-regular size-6 !p-0')}>
          ...
        </IconButton>
      )}

      {/* Last page */}
      {totalPage > 3 && (
        <IconButton
          className={clsx(
            'rounded !text-md-regular size-6 !p-0',
            page === totalPage &&
              '!bg-none !bg-primary group-data-[theme="Heartland"]/root:text-base-000'
          )}
          onClick={() => setPage(totalPage)}
        >
          {totalPage}
        </IconButton>
      )}
      <IconButton onClick={onNextPage}>
        <ChevronRightIcon className="size-3" />
      </IconButton>
      <IconButton onClick={() => setPage(totalPage)}>
        <ChevronDoubleRightIcon className="size-3" />
      </IconButton>
    </nav>
  );
}
