import { useMemo } from 'react';
import { useForm } from 'react-hook-form';
import { SupportedCropsOptions } from '../../../../constants';
import { useYearFilter } from '../../../../contexts/app-filter-context';
import { useUser } from '../../../../contexts/auth-context';
import { useFieldPolygonsByYear } from '../../../../hooks/field/use-field-polygons';
import {
  useGetInboundGrains,
  useUpdateInboundGrain,
} from '../../../../hooks/inbound-grain/use-inbound-grain';
import { InboundGrainItem } from '../../../../types/inbound-grain';
import { getYearOptions } from '../../../../utils';
import ComboDatePicker from '../../../commons/inputs/ComboDatePicker';
import Select, { AwaitSelect } from '../../../commons/Select';
import Table, { TableConfigs } from '../../../commons/table/Table';
import TableLayout from '../../commons/TableLayout';

export default function InboundGrain() {
  const user = useUser();
  const year = useYearFilter();

  const { data, isFetching } = useGetInboundGrains({
    userId: user?.id ?? '',
    partner: user?.network_partner ?? 'New Vision',
  });

  const { data: fields, isFetching: fetchingFieldOptions } =
    useFieldPolygonsByYear(user?.id ?? '', year ?? '');
  const fieldOptions = useMemo(
    () =>
      fields?.map((item) => ({
        value: item.field,
        label: item.field,
      })),
    [fields]
  );
  const { mutateAsync: update } = useUpdateInboundGrain();

  const handleUpdate = async (values: any) => {
    update({
      ...(values.field_applied && { field_applied: values.field_applied }),
      ...(values.crop_year && { crop_year: values.crop_year }),
      uniqueid: values.uniqueid,
      grossdatetime: values.grossdatetime,
    });
  };

  const handleUpdateAppliedField = async ({
    item,
    newValue,
  }: {
    item: InboundGrainItem;
    newValue: string;
  }) => {
    if (!newValue) return;
    if (item.field_applied !== newValue) {
      handleUpdate({ ...item, field_applied: newValue });
    }
  };

  const handleUpdateCropYear = async ({
    item,
    newValue,
  }: {
    item: InboundGrainItem;
    newValue: string;
  }) => {
    if (!newValue) return;
    if (item?.crop_year !== newValue) {
      handleUpdate({ ...item, crop_year: newValue });
    }
  };

  const tableConfigs: TableConfigs = {
    cols: [
      { key: 'shipmentdate', name: 'Shipment Date' },
      { key: 'ticketnumber', name: 'Ticket Number' },
      { key: 'partner', name: 'Coop' },
      { key: 'ticketlocationdescription', name: 'Location' },
      { key: 'commodity', name: 'Commodity' },
      {
        key: 'field_applied',
        name: 'Applied Field',
        type: 'element',
        element: (item: InboundGrainItem) => {
          return (
            <AwaitSelect
              options={[
                {
                  label: 'Select',
                  value: '',
                },
                ...(fieldOptions ?? []),
              ]}
              name="field_applied"
              defaultValue={item?.field_applied || ''}
              isLoading={fetchingFieldOptions}
              onChange={(e) =>
                handleUpdateAppliedField({ item, newValue: e.target.value })
              }
              autoSelect={false}
            />
          );
        },
      },
      {
        key: 'crop_year',
        name: 'Crop Year',
        type: 'element',
        element: (item: InboundGrainItem) => {
          return (
            <Select
              options={[
                {
                  label: 'Select',
                  value: '',
                },
                ...getYearOptions(10, 5),
              ]}
              name="crop_year"
              defaultValue={item?.crop_year || ''}
              onChange={(e) =>
                handleUpdateCropYear({ item, newValue: e.target.value })
              }
              autoSelect={false}
            />
          );
        },
      },
      {
        key: 'grossweight',
        name: 'Gross Weight',
        type: 'number',
        emptyValue: 0,
        unit: 'pound',
        numberStyle: 'unit',
        total: true,
      },
      {
        key: 'grossquantity',
        name: 'Gross Quantity',
        type: 'number',
        unit: 'bu',
        total: true,
      },
      {
        key: 'deductedquantity',
        name: 'Deducted Quantity',
        type: 'number',
        emptyValue: 0,
        total: true,
        className: 'text-red',
        unit: 'bu',
      },
      {
        key: 'netweight',
        name: 'Net Weight',
        type: 'number',
        unit: 'pound',
        numberStyle: 'unit',
        total: true,
      },
      {
        key: 'Test Weight',
        name: 'Test Weight',
        type: 'number',
        unit: 'pound',
        numberStyle: 'unit',
        total: true,
      },
      {
        key: 'Moisture',
        name: 'Moisture',
        type: 'number',
      },
      {
        key: 'netquantity',
        name: 'Net Quantity',
        type: 'number',
        unit: 'bu',
        total: true,
      },
      { key: 'price', name: 'Cash Price', type: 'currency', emptyValue: 0 },
      { key: 'basis', name: 'Cash Basis', type: 'currency', emptyValue: 0 },
    ],
  };

  // Handle filter
  const filterForm = useForm();
  const values = filterForm.watch();
  const handleFilter = () => {
    return (
      data?.filter((item) => {
        return (
          (!values.crop || item.commoditydescription.includes(values.crop)) &&
          (!values.shipmentdate ||
            new Date(item.shipmentdate).toDateString() ===
              new Date(values.shipmentdate).toDateString())
        );
      }) ?? []
    );
  };

  const filterInputs = (
    <>
      <Select
        options={[{ label: 'All', value: '' }, ...SupportedCropsOptions]}
        name={'crop'}
        label="Crop"
      />
      <ComboDatePicker name={'shipmentdate'} label="Shipment Date" />
    </>
  );

  return (
    <div className="card">
      <TableLayout title="Inbound Grain Tickets">
        <Table
          configs={tableConfigs}
          data={data}
          pagination={{ size: 10 }}
          hasTotalRow
          loading={isFetching}
          filter={{
            form: filterForm,
            handler: handleFilter,
            element: filterInputs,
          }}
          download
        />
      </TableLayout>
    </div>
  );
}
