import { FeatureCollection } from '@deck.gl-community/editable-layers';
import { Input, Tab, TabGroup, TabList } from '@headlessui/react';
import { ArrowsPointingInIcon, Bars3CenterLeftIcon, XCircleIcon } from '@heroicons/react/24/solid';
import clsx from 'clsx';
import { cloneElement, useState } from 'react';
import { NavLink, useParams } from 'react-router-dom';
import { theme } from 'twin.macro';
import { useControlledUser, useUser } from '../../../contexts/auth-context';
import { MapContext } from '../../../contexts/map-context';
import { fillColors } from '../../../utils';
import LikelihoodExpectedRevenue from './LikelihoodExpectedRevenue';
import MapSelections from './MapDataSelections';
import MapSection from './MapSection';
import MapToolbar from './MapToolbar';
import RelationshipLineChart from './RelationshipLineChart';
import RevenueByField from './RevenueByField';
import RevenuePerAcre from './RevenuePerAcre';
import { tabs } from './helpers';
import StudyList from './StudyList';
import TextInput from '../../../components/commons/inputs/TextInput';

function Map() {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const [trigger, setTrigger] = useState(0);
  const [isShowPoints, setIsShowPoints] = useState(false);
  const [selectedArea, setSelectedArea] = useState('Corn');
  const [page, setPage] = useState(0);
  const [selectedDate, setSelectedDate] = useState('');
  const [selectedField, setSelectedField] = useState<string>('');
  const [selectedZone, setSelectedZone] = useState<string>('');
  const [selectedCrop, setSelectedCrop] = useState<string>('');
  const [selectedStudyIndex, setSelectedStudyIndex] = useState(0);
  const [studies, setStudies] = useState<string[]>([]);
  const [selectedDrawMode, setSelectedDrawMode] = useState<number>(0);
  const [creatingStudy, setCreatingStudy] = useState<boolean>(false);
  const [studyDetails, setStudyDetails] = useState<any>({});
  const [modalMode, setModalMode] = useState<string>('');
  const user = useControlledUser();
  const [viewState, setViewState] = useState({
    longitude: user?.lon ? parseFloat(user?.lon) : -98.381933,
    latitude: user?.lon ? parseFloat(user?.lat) : 44.704991,
    zoom: 12,
    pitch: 30,
    bearing: 0,
  });
  const [createStudy, setCreateStudy] = useState<boolean>(false);
  const [selectedFeatureIndexes, setSelectedFeatureIndexes] = useState<
    number[]
  >([]);
  const [selectedChart, setSelectedChart] = useState<string>('crop');
  const [selectedChartCrop, setSelectedChartCrop] = useState<string>('corn');
  const [editMode, setEditMode] = useState<string>('');
  const [saveEditingStudy, setSaveEditingStudy] = useState<any>(null);
  const [selectedFieldIndex, setSelectedFieldIndex] = useState<string>('');
  const [changingZoom, setChangingZoom] = useState(false);
  const [changingDate, setChangingDate] = useState(false);

  const drawModes = [
    'Move',
    'Polygon',
    'Square',
    'Rectangle',
    'Circle',
    'Drag & Draw',
    'Edit',
    'Transform',
  ];

  const [centerTrigger, setCenterTrigger] = useState(0);

  const [myFeatureCollection, setMyFeatureCollection] =
    useState<FeatureCollection>({
      type: 'FeatureCollection',
      features: [
        /* insert features here */
      ],
    });
  const { type: typeParam = '' } = useParams();

  const url = window.location.pathname;

  return (
    <MapContext.Provider
      value={{
        centerTrigger,
        setCenterTrigger,
        changingDate,
        setChangingDate,
        changingZoom,
        setChangingZoom,
        selectedChartCrop,
        setSelectedChartCrop,
        selectedChart,
        setSelectedChart,
        modalMode,
        setModalMode,
        selectedArea,
        setSelectedArea,
        selectedFieldIndex,
        setSelectedFieldIndex,
        saveEditingStudy,
        setSaveEditingStudy,
        editMode,
        setEditMode,
        trigger,
        setTrigger,
        myFeatureCollection,
        setMyFeatureCollection,
        selectedFeatureIndexes,
        setSelectedFeatureIndexes,
        createStudy,
        setCreateStudy,
        viewState,
        setViewState,
        studyDetails,
        setStudyDetails,
        creatingStudy,
        setCreatingStudy,
        selectedDrawMode,
        setSelectedDrawMode,
        studies,
        setStudies,
        selectedStudyIndex,
        setSelectedStudyIndex,
        selectedZone,
        setSelectedZone,
        selectedField,
        setSelectedField,
        selectedDate,
        setSelectedDate,
        page,
        setPage,
      }}
    >
      <div className="w-full">
        <TabGroup
          className="flex w- flex-col items-center space-y-8"
          selectedIndex={selectedIndex}
          onChange={setSelectedIndex}
        >
          <div className="w-full items-center justify-center flex">
            {/* Tab list */}
            <TabList className="card p-3 w-fit flex justify-between whitespace-nowrap space-x-4">
              {Object.keys(tabs).map((key) => (
                <Tab key={key}>
                  {({ hover }) => (
                    <NavLink
                      to={`${url
                        .split('/')
                        .slice(0, url.split('/').length - 1)
                        .join('/')}/${key}`}
                      className={clsx(
                        'text-base-900',
                        'hover:text-primary',
                        'flex items-center space-x-2 text-md-regular'
                      )}
                    >
                      {({ isActive }) => (
                        <>
                          {cloneElement(
                            tabs[key].icon({
                              fill:
                                isActive || hover
                                  ? theme`colors.primary`
                                  : fillColors(user?.network_partner)?.inactive,
                            })
                          )}
                          <p className={isActive ? 'text-primary' : ''}>
                            {tabs[key].label}
                          </p>
                        </>
                      )}
                    </NavLink>
                  )}
                </Tab>
              ))}
            </TabList>

            {/* Switch */}
          </div>

          <div className="w-full grid grid-cols-12 gap-8">
            <div className="col-span-4">
              <div className="card">
                <MapSelections />
                {/* Charts */}
                <div className=" p-6">
                  <RelationshipLineChart
                    date={selectedDate}
                    crop={selectedArea}
                    relationship={typeParam}
                  />
                </div>

                <div className="flex space-y-0 flex-col gap-4">
                  {selectedChart === 'crop' && (
                    <div className=" p-6">
                      <RevenuePerAcre />
                    </div>
                  )}
                  {selectedChart === 'field' && (
                    <div className=" p-6 ">
                      <RevenueByField crop={selectedArea} />
                    </div>
                  )}
                  {selectedChart === 'zone' && (
                    <div className=" p-6">
                      <LikelihoodExpectedRevenue crop={selectedArea} />
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="col-span-8 space-y-4">
              <div className="w-full relative flex justify-center items-center">
                <div onClick={() => setCenterTrigger(centerTrigger + 1)} className="flex gap-2 absolute right-4 top-[140px] cursor-pointer z-[99] bg-darkerGray px-4 py-2 rounded-md">

                  <ArrowsPointingInIcon className="h-5 w-5 cursor-pointer text-white" />
                  </div>
                {editMode && (
                  <div className="flex items-center gap-2 absolute top-[130px] cursor-pointer z-[99] bg-darkerGray px-4 py-2 rounded-md">
                    <p>Editing Study: </p>
                    <input
                      className="bg-transparent px-2 border-[1px] rounded-md"
                      value={saveEditingStudy?.name}
                      onChange={(e) =>
                        setSaveEditingStudy({
                          ...saveEditingStudy,
                          name: e.target.value,
                        })
                      }
                    />
                    <XCircleIcon
                      onClick={() => {
                        setEditMode('');
                        setMyFeatureCollection({
                          type: 'FeatureCollection',
                          features: [],
                        });
                      }}
                      className="h-5 w-5 cursor-pointer text-white"
                    />
                  </div>
                )}
                {modalMode === 'drawingModeSelected' && (
                  <>
                    <div
                      className={`absolute z-[99] bg-white text-black ${editMode ? 'top-[180px]' : 'top-[160px]'} px-4 py-2 rounded-md`}
                    >
                      <p>
                        Click point to start drawing{' '}
                        {drawModes[selectedDrawMode]}
                      </p>
                    </div>
                    <div
                      className={`absolute z-[99] ${editMode ? 'top-[220px]' : 'top-[200px]'}  w-6 h-3 bg-white`}
                      style={{ clipPath: 'polygon(0 0, 100% 0, 50% 100%)' }}
                    ></div>
                  </>
                )}
                {modalMode === 'drawingShape' && (
                  <>
                    <div
                      className={`absolute z-[99] bg-white text-black ${editMode ? 'top-[180px]' : 'top-[160px]'} px-4 py-2 rounded-md`}
                    >
                      {drawModes[selectedDrawMode] === 'Polygon' ? (
                        <p>Click first point again to close Polygon</p>
                      ) : (
                        <p>Draw {drawModes[selectedDrawMode]}</p>
                      )}
                    </div>
                    <div
                      className={`absolute z-[99] ${editMode ? 'top-[220px]' : 'top-[200px]'}  w-6 h-3 bg-white`}
                      style={{ clipPath: 'polygon(0 0, 100% 0, 50% 100%)' }}
                    ></div>
                  </>
                )}

                {modalMode === 'editPolygons' && (
                  <>
                    <div
                      className={`absolute z-[99] bg-white text-black ${editMode ? 'top-[180px]' : 'top-[160px]'} px-4 py-2 rounded-md`}
                    >
                      <p>Edit Polygon Points</p>
                    </div>
                    <div
                      className={`absolute z-[99] ${editMode ? 'top-[220px]' : 'top-[200px]'}  w-6 h-3 bg-white`}
                      style={{ clipPath: 'polygon(0 0, 100% 0, 50% 100%)' }}
                    ></div>
                  </>
                )}
                {modalMode === 'transformPolygons' && (
                  <>
                    <div
                      className={`absolute z-[99] bg-white text-black ${editMode ? 'top-[180px]' : 'top-[160px]'} px-4 py-2 rounded-md`}
                    >
                      <p>Transform Polygons</p>
                    </div>
                    <div
                      className={`absolute z-[99] ${editMode ? 'top-[220px]' : 'top-[200px]'}  w-6 h-3 bg-white`}
                      style={{ clipPath: 'polygon(0 0, 100% 0, 50% 100%)' }}
                    ></div>
                  </>
                )}

                {modalMode === 'drawingCompleted' && (
                  <>
                    <div
                      className={`cursor-pointer flex gap-2 items-center absolute z-[99] bg-white text-black ${editMode ? 'top-[180px]' : 'top-[160px]'} px-4 py-2 rounded-md`}
                    >
                      <p className="text-center">
                        {drawModes[selectedDrawMode]} Finished
                      </p>
                      <div
                        onClick={() => setModalMode('drawingModeSelected')}
                        className="bg-primary text-white px-2 py-1 rounded-md"
                      >
                        Ok
                      </div>
                      <div
                        onClick={() => {
                          let newFeatures = myFeatureCollection.features.slice(
                            0,
                            -1
                          );
                          setMyFeatureCollection({
                            type: 'FeatureCollection',
                            features: newFeatures,
                          });
                          setModalMode('drawingModeSelected');
                        }}
                        className="bg-darkGray text-white px-2 py-1 rounded-md text-center"
                      >
                        Delete Shape
                      </div>
                    </div>
                    <div
                      className={`absolute z-[99] ${editMode ? 'top-[220px]' : 'top-[200px]'}  w-6 h-3 bg-white`}
                      style={{ clipPath: 'polygon(0 0, 100% 0, 50% 100%)' }}
                    ></div>
                  </>
                )}
                {editMode && (
                  <div
                    onClick={() => {
                      setEditMode('save');
                      setSelectedDrawMode(0);
                      setModalMode('');
                    }}
                    className="absolute bottom-4 cursor-pointer z-[99] bg-primary px-4 py-2 rounded-md"
                  >
                    <p>Save Study</p>
                  </div>
                )}
                <MapSection
                  selectedArea={selectedArea}
                  setSelectedDate={setSelectedDate}
                />
              </div>
              <MapToolbar />
              <StudyList />
            </div>
          </div>
        </TabGroup>
      </div>
    </MapContext.Provider>
  );
}

export default Map;
