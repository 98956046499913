import { useEffect, useState } from 'react';
import { PARQUET_BUCKET } from '../../constants';
import { getwxData } from '../../utils';


export const useFieldsWData = ({
  date,
  userId,
  year,
  s3Keys,
  type,
}: {
  date: string;
  userId: string;
  year: string;
  s3Keys: string[];
  type: string;
}) => {
  const [wData, setWData] = useState<any[]>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      if (!date || !userId || !year) {
        setLoading(false);
        return;
      }
      const skey = `${userId}_${year}_${type}_${date}`;
      console.log('field getting data with skey: ', skey, 'from s3Keys: ', s3Keys);
      const skeys = s3Keys.filter(
        (key) => key.includes(skey) && key !== `${skey}_.parquet`
      );
      console.log('field getting data from skeys: ', skeys);
      const promises = skeys.map(async (key) => {
        const wData = await getwxData(PARQUET_BUCKET, key);
        return wData;
      });

      const wData = await Promise.all(promises);
      setWData(wData);
      setLoading(false);
    };

    fetchData();
  }, [date, s3Keys, type, userId, year]);

  return {
    wData,
    loading,
  };
};
