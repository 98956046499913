import { useMemo } from 'react';
import Table, { TableConfigs } from '../../../commons/table/Table';
import TableLayout from '../../commons/TableLayout';

interface SubtotalListProps {
  data: any[];
  loading: boolean;
}

export default function SubtotalList({ data, loading }: SubtotalListProps) {
  const tableConfigs: TableConfigs = useMemo(() => {
    return {
      cols: [
        {
          name: 'Zone',
          key: 'zone',
        },
        {
          name: 'Acres',
          key: 'acres',
        },
        {
          name: 'Prescription',
          key: 'prescription',
        },
        {
          name: 'Prescription Quantity',
          key: 'prescriptionQuantity',
          type: 'number',
          total: true,
        },
        {
          name: 'Prescription Cost',
          key: 'prescriptionCost',
          type: 'currency',
          total: true,
        },
      ],
    };
  }, []);

  return (
    <TableLayout title={`Prescription Subtotals`}>
      <Table
        configs={tableConfigs || {}}
        data={data}
        hasTotalRow
        totalRowVariant="secondary"
        loading={loading}
      />
    </TableLayout>
  );
}
