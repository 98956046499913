import { groupBy } from 'lodash';
import { useYearFilter } from '../../../../contexts/app-filter-context';
import { useUser } from '../../../../contexts/auth-context';
import {
  useDeleteStorage,
  useStorages,
} from '../../../../hooks/storage/use-storage';
import { StorageItem } from '../../../../types/storage';
import Table, { RowActions, TableConfigs } from '../../../commons/table/Table';
import TableLayout from '../../commons/TableLayout';
import EditStorageForm from './EditStorageForm';

interface StorageListProps {}

const tableConfigs: TableConfigs = {
  cols: [
    {
      key: 'storageName',
      name: 'Name',
    },
    {
      key: 'contract',
      name: 'Contract',
    },
    {
      key: 'expectedDeliveryMonth',
      name: 'Expected Delivery Month',
    },
    {
      key: 'bushels',
      name: 'Bushels',
      type: 'number',
      total: true,
    },
    {
      key: 'cost',
      name: 'Cost (weekly per bushel)',
    },
    {
      key: 'fieldApplied',
      name: 'Field Applied',
    },
  ],
};

const Tables = [
  {
    name: 'On-Farm',
    tooltip: 'demo',
  },
  {
    name: 'Commercial',
    tooltip: 'demo',
  },
];

export default function StorageList(props: StorageListProps) {
  const user = useUser();
  const year = useYearFilter();

  const { data = [], isFetching } = useStorages({
    userId: user?.id ?? '',
    year: year ?? '',
  });

  const { mutate: deleteStorage } = useDeleteStorage();

  const dataGroupByStorageType = groupBy(data, 'storageType');

  const rowActions: RowActions = {
    edit: {
      onSelect: (item) => {},
      form: ({ item, onClose }) => (
        <EditStorageForm data={item} onCancel={onClose} />
      ),
    },
    delete: (item: StorageItem) => {
      if (!user) return;
      deleteStorage(item);
    },
  };

  return (
    <TableLayout title="Current Storage">
      {Tables.map(({ name, tooltip }) => (
        <Table
          key={name}
          configs={tableConfigs}
          data={dataGroupByStorageType[name]}
          title={{
            name: name,
            tooltip: tooltip,
          }}
          hasTotalRow
          loading={isFetching}
          rowActions={rowActions}
        />
      ))}
    </TableLayout>
  );
}
