import { useEffect, useState } from 'react';
import { WEATHER_BUCKET, weatherTypesToBuckets } from '../../constants';
import { getwxData } from '../../utils';

export const useWData = ({
  state,
  date,
  s3Keys,
  type,
  time,
}: {
  state: string;
  date: string;
  s3Keys: string[];
  type: string;
  time: string;
}) => {
  const [wData, setWData] = useState<any[]>();
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      if (!date || !state || !type) {
        setLoading(false);
        return;
      }
      let skey = '';
      if (type !== '24hrPrecipitation') {
        skey =
          weatherTypesToBuckets[type as keyof typeof weatherTypesToBuckets] +
          '/' +
          `${state}_${date} ${time}`;
      } else {
        skey = weatherTypesToBuckets[type as keyof typeof weatherTypesToBuckets] +
        '/' +
        `${state}_${date}`;
      }
      console.log('getting data with skey: ', skey);
      const skeys = s3Keys.filter((key) => {
        return key.includes(skey) && key !== `${skey}_.parquet`;
      });
      console.log('data skeys: ', skeys);
      const promises = skeys.map(async (key) => {
        const wData = await getwxData(WEATHER_BUCKET, key);
        return wData;
      });

      const wData = await Promise.all(promises);
      setWData(wData);
      setLoading(false);
    };

    fetchData();
  }, [date, s3Keys, type, state, time]);

  return {
    wData,
    loading,
  };
};
