import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Tab,
  TabGroup,
  TabList,
} from '@headlessui/react';
import clsx from 'clsx';
import DeckGL from 'deck.gl';
import { useEffect, useState } from 'react';
import Map from 'react-map-gl';
import { theme } from 'twin.macro';
import ClockIcon from '../../assets/icons/ClockIcon';
import CloudIcon from '../../assets/icons/CloudIcon';
import DeltaTIcon from '../../assets/icons/DeltaTIcon';
import EvapotranspirationIcon from '../../assets/icons/EvapotranspirationIcon';
import GrowingDayIcon from '../../assets/icons/GrowingDayIcon';
import HailIcon from '../../assets/icons/HailIcon';
import HeavyRainNightIcon from '../../assets/icons/HeavyRainNightIcon';
import IceIcon from '../../assets/icons/IceIcon';
import MixWeatherIcon from '../../assets/icons/MixWeatherIcon';
import PrecipipatitionRateIcon from '../../assets/icons/PrecipipatitionRateIcon';
import RadarForecastIcon from '../../assets/icons/RadarForecastIcon';
import RadarIcon from '../../assets/icons/RadarIcon';
import RainIcon from '../../assets/icons/RainIcon';
import SnowIcon from '../../assets/icons/SnowIcon';
import SoilMoistureIcon from '../../assets/icons/SoilMoistureIcon';
import SoilTempIcon from '../../assets/icons/SoilTempIcon';
import SunnyStormLightningIcon from '../../assets/icons/SunnyStormLightningIcon';
import TemperatureIcon from '../../assets/icons/TemperatureIcon';
import {
  DEFAULT_CENTER,
  MAP_STYLE,
  mapTooltipStyles,
  stateOptions,
  weatherTabs,
} from '../../constants';
import { useControlledUser, useMainUser, useUser } from '../../contexts/auth-context';
import { formatDate, lineChartColorsByNetwork } from '../../utils';
import Button from '../commons/Button';
import CardHeader from '../commons/CardHeader';
import SingleLineChart from '../commons/charts/SingleLineChart';
import ComboDatePicker from '../commons/inputs/ComboDatePicker';
import Select from '../commons/Select';
import { useS3Keys } from './useS3Keys';
import { useWData } from './useWData';
import { useLayers } from './useLayers';
import { WeatherContext } from '../../contexts/weather-context';
import DateCalendar from '../commons/calendar/DateCalendar';
import { CalendarIcon } from '../../assets/icons';
import TimelineSlider from './TimelineSlider';
import { useTimeSet } from './useTimeSet';
import { useDateSet } from './useDateSet';



import { useFieldsS3Keys } from './useFieldsS3Keys';
import { useFieldsCenter } from './useFieldCenter';
import { useFieldsLayers } from './useFieldsLayers';
import { useFieldsWData } from './useFieldsWData';

const historicalWeatherDataOps = [
  { label: 'Temperature', value: 'temperature' },
  { label: 'Precipitation', value: 'precipitation' },
];

const agriWeatherElements = [
  { label: 'Growing Degree Days', value: '27', icon: <GrowingDayIcon /> },
  { label: 'Soil Temperature', value: '72°', icon: <SoilTempIcon /> },
  { label: 'Soil Moisture', value: '38%', icon: <SoilMoistureIcon /> },
  {
    label: 'Evapotranspiration',
    value: '12%',
    icon: <EvapotranspirationIcon />,
  },
  { label: 'DeltaT', value: '72°', icon: <DeltaTIcon /> },
];

const forecast = [
  {
    label: 'This Afternoon',
    value: '80°',
    icon: SunnyStormLightningIcon,
    content: 'Chance Showers And Thunderstorms',
  },
  {
    label: 'Tonight',
    value: '51°',
    icon: HeavyRainNightIcon,
    content: 'Chance Showers And Thunderstorms then Mostly Cloudy',
  },
  {
    label: 'Tomorrow',
    value: '70°',
    icon: CloudIcon,
    content: 'Mostly Cloudy',
  },
];

export const tabs = [
  { label: 'Radar', value: 'radar', icon: RadarIcon },
  { label: 'Radar Forecast', value: 'radarForecast', icon: RadarForecastIcon },
  { label: 'Hail', value: 'hail', icon: HailIcon },
  {
    label: 'Precipitation Rate',
    value: 'precipitationRate',
    icon: PrecipipatitionRateIcon,
  },
  { label: '24hr Precipitation', value: '24hrPrecipitation', icon: ClockIcon },
];

const mapLegend = [
  {
    label: 'Rain',
    icon: RainIcon,
    color:
      'linear-gradient(90deg, #58C36C 0%, #289D57 20.5%, #259C2A 40%, #F8E540 60%, #F47D27 80%, #F42727 100%',
  },
  {
    label: 'Ice',
    icon: IceIcon,
    color:
      'linear-gradient(90deg, #B8A4E9 0%, #9C84CF 20%, #8972BE 40%, #7D65B3 60%, #6C53A3 80%, #5C4294 100%',
  },
  {
    label: 'Mix',
    icon: MixWeatherIcon,
    color:
      'linear-gradient(90deg, #D36B9E 0%, #CB6196 20.5%, #C05389 40%, #B03B74 60%, #A22561 80%, #69113B 100%',
  },
  {
    label: 'Snow',
    icon: SnowIcon,
    color:
      'linear-gradient(90deg, #88EEF5 0%, #77D4DB 20.5%, #62B4BD 40%, #4C8F9A 60%, #275763 80%, #173E4C 100%',
  },
];

export default function Weather() {
  const user = useControlledUser();
  const [historicalWeatherData, setHistoricalWeatherData] =
    useState('temperature');

  const [selectedTab, setSelectedTab] = useState('radar');
  const [selectedState, setSelectedState] = useState(user?.state || 'SD');

  const [selectedDate, setSelectedDate] = useState(new Date().toISOString().split('T')[0]);
  const [selectedTime, setSelectedTime] = useState('00:00');

  const [viewState, setViewState] = useState({
    longitude: Number(user?.lon) || DEFAULT_CENTER.lon,
    latitude: Number(user?.lat) || DEFAULT_CENTER.lat,
    zoom: 6,
    pitch: 30,
    bearing: 0,
  });

  const handleViewStateChange = ({ viewState }: any) => {
    setViewState(viewState);
  };

  const { s3Keys, loading: s3KeysLoading } = useS3Keys({
    state: selectedState,
    date: selectedDate,
    type: selectedTab,
  });

  const { timeSet } = useTimeSet(s3Keys, selectedTab);

  console.log('timeSet', timeSet);
  const { dateSet } = useDateSet(s3Keys, selectedTab);

  useEffect(() => {
    if (selectedTab === '24hrPrecipitation') {
      setSelectedTime(dateSet[0]);
    } else {
      setSelectedTime(timeSet[0]);
    }
  }, [])

  const { s3Keys: fieldS3Keys, loading: fieldS3KeysLoading } = useFieldsS3Keys({
    userId: user?.id || '',
    year: selectedDate.split('-')[0] || '',
  });


  const fieldDate = fieldS3Keys[fieldS3Keys.length - 1]?.split('_')[3] || '2024-05-20';

  const { wData: fieldWData, loading: fieldWDataLoading } = useFieldsWData({
    date: fieldDate,
    userId: user?.id || '',
    year: selectedDate.split('-')[0] || '2024',
    s3Keys: fieldS3Keys,
    type: 'revenue'
  });

  const { layers: fieldLayers, hoverInfo: fieldHoverInfo } = useFieldsLayers({
    wData: fieldWData
  });


  const { center } = useFieldsCenter(fieldWData);

  useEffect(() => {
    if (center) {
      const newCenter: any = {
        longitude: center.lon,
        latitude: center.lat,
      };
      setViewState((prev: any) => ({ ...prev, ...newCenter }));
    }
  }, [center]);

  // console.log('fieldS3Keys', fieldS3Keys);
  // console.log('fieldWData', fieldWData);
  // console.log('fieldLayers', fieldLayers);




  const { wData, loading: wDataLoading } = useWData({
    state: selectedState,
    date: selectedDate,
    s3Keys,
    type: selectedTab,
    time: selectedTime,
  });

  const { layers, hoverInfo } = useLayers({
    wData,
    type: selectedTab,
  });


  const getTooltip = (info: any) => {
    if (info?.layer?.id?.includes('user-field-map')) {
      return null
    }

    if (info.index === -1) return null;
    const value = parseFloat(info.layer.props.data.value?.[info.index]).toFixed(
      2
    );
    return {
      html: `
        <div>
          
          <p>
            <b>${weatherTabs[selectedTab as keyof typeof weatherTabs]}:</b> ${value}
          </p>
        </div>
      `,
      style: mapTooltipStyles,
    };
  };

  const [isOpen, setIsOpen] = useState(false);

  return (
    <WeatherContext.Provider
      value={{
        selectedDate,
        setSelectedDate,
        selectedTab,
        setSelectedTab,
        selectedTime,
        setSelectedTime,
      }}
    >
      <div className="grid grid-cols-12 gap-8">
        {/* Toolbar */}
        <div className="col-span-12 flex justify-center">
          <div className="card p-3 !rounded-lg">
            <TabGroup>
              <TabList className="flex items-center space-x-4">
                {tabs.map((tab) => (
                  <Tab
                    onClick={() => setSelectedTab(tab.value)}
                    key={tab.value}
                    className="outline-none"
                  >
                    {({ selected }) => (
                      <div className="flex items-center space-x-2">
                        <tab.icon
                          fill={
                            !selected
                              ? theme`colors.base.800`
                              : theme`colors.primary`
                          }
                        />
                        <p
                          className={clsx({
                            'text-base-800': !selected,
                            'text-primary': selected,
                          })}
                        >
                          {tab.label}
                        </p>
                      </div>
                    )}
                  </Tab>
                ))}
              </TabList>
            </TabGroup>
          </div>
          {/* <div className="card p-3 flex space-x-4 !rounded-lg">
            {mapLegend.map((legend) => (
              <div key={legend.label} className="flex items-center space-x-2">
                <legend.icon />
                <p className="text-base-800">{legend.label}</p>
                <span
                  className={clsx('rounded-full w-24 h-4')}
                  style={{
                    background: legend.color,
                    boxShadow: '4px 4px 8px 0px #FFFFFF80 inset',
                  }}
                ></span>
              </div>
            ))}
          </div> */}
        </div>
        <div className="col-span-3 space-y-8">
          <div className="border-0 flex flex-col flex-start w-full gap-4">
            <Select
              name="location"
              options={stateOptions}
              onChange={(e) => {
                setSelectedState(e.target.value);
              }}
              defaultValue={user?.state || 'SD'}
            />
            {/* <ComboDatePicker name="date" /> */}
            <div className=" flex px-4 border-0 gap-4 text-center mb-0 border-0 max-w-max p-2 rounded-md cursor-pointer">
              <Popover className=" flex">
                <PopoverButton className="flex items-center gap-4 justify-center ">
                  <CalendarIcon
                    className="w-6 h-6 cursor-pointer"
                    fill={'#A3B4C2'}
                  />
                  <p>{formatDate(selectedDate)}</p>
                </PopoverButton>
                <PopoverPanel className="absolute mt-[30px]">
                  <DateCalendar
                    availableYears={[2023, 2024]}
                    defaultYear={2024}
                    onChange={(date) => {
                      const isoString = date.toISOString(); // "2024-06-09T05:00:00.000Z"
                      const formattedDate = isoString.split('T')[0];
                      setSelectedDate(formattedDate);
                    }}
                  />
                </PopoverPanel>
              </Popover>
            </div>
          </div>

          {/* <div className="card p-4 py-5 space-y-4">
            <h3 className="display-md-bold">Your Current Weather</h3>
            <p className="text-sm-regular text-base-500 group-data-[theme=Heartland]/root:text-primary">
              Last Updated June 18, 2024 12:02 PM CDT
            </p>
            <div className="flex justify-center items-center gap-2">
              <p className="display-2xl-bold">80°</p>
              <span>
                <TemperatureIcon />
              </span>
            </div>
            <div className="flex items-center gap-8">
              <span>
                <SunnyStormLightningIcon
                  fill={
                    user?.network_partner === 'Heartland'
                      ? '#646568'
                      : undefined
                  }
                />
              </span>
              <p className="text-lg-bold">Showers And Thunderstorms</p>
            </div>
            <p className="text-sm-regular text-base-500 group-data-[theme=Heartland]/root:text-secondary">
              A chance of showers and thunderstorms. Cloudy. High near 80, with
              temperatures falling to around 66 in the afternoon. West southwest
              wind 15 to 20 mph, with gusts as high as 30 mph. Chance of
              precipitation is 40%. New rainfall amounts less than a tenth of an
              inch possible.
            </p>
            <div className="border-t pt-4">
              <h4 className="display-sm-bold mb-2">80°/ 51°</h4>
              <p>
                Feels like: <b>82°</b>
              </p>
              <p>
                Humidity: <b>70%</b>
              </p>
              <p>
                Wind: <b>WNW 6 mph</b>
              </p>
              <p>
                Sunrise: <b>4:45 am</b>
              </p>
              <p>
                Sunset: <b>9:32 pm</b>
              </p>
              <p>
                Precipitation Chance: <b>40%</b>
              </p>
            </div>
          </div> */}
          {/* <div className="card p-4 py-5">
            {forecast.map((data) => (
              <div className="border-b last:border-b-0 py-4 first:pt-0 space-y-2">
                <div className="flex items-center gap-2">
                  <span>
                    <data.icon
                      fill={
                        user?.network_partner === 'Heartland'
                          ? '#646568'
                          : undefined
                      }
                    />
                  </span>
                  <h3 className="display-sm-bold">
                    {data.label} {data.value}
                  </h3>
                </div>
                <p className="text-base-300 group-data-[theme=Heartland]/root:text-secondary">
                  {data.content}
                </p>
              </div>
            ))}
          </div> */}
        </div>
        <div className="col-span-9 pt-4 space-y-8">
          <TimelineSlider
            timeSet={timeSet}
            value={timeSet.indexOf(selectedTime)}
            cachedTime=""
            cachedDate=""
            onChange={(e) => {
              if (selectedTab !== '24hrPrecipitation') {
                setSelectedTime(timeSet[parseInt(e)])
              } else {
                setSelectedDate(dateSet[parseInt(e)])
              }
            }}
            min={0}
            max={timeSet.length - 1}
          />
          <div className="card relative overflow-hidden w-full h-[500px]">
            <DeckGL
              viewState={viewState}
              controller={true}
              onViewStateChange={handleViewStateChange}
              layers={[layers, fieldLayers]}
              getTooltip={getTooltip}
            >
              <Map
                reuseMaps
                mapboxAccessToken={process.env.REACT_APP_MAPBOX_ACCESS_TOKEN}
                mapStyle={MAP_STYLE}
              />
            </DeckGL>
          </div>
          {/* <div className="grid grid-cols-9 gap-8">
            <div className="col-span-6 space-y-8">
              <div className="card p-6">
                <CardHeader title="Historical Weather Data">
                  <div className="flex border border-base-800 rounded-lg overflow-hidden">
                    {historicalWeatherDataOps.map((data) => (
                      <span
                        key={data.value}
                        className={clsx('p-2 cursor-pointer', {
                          'bg-base-800': historicalWeatherData === data.value,
                          'group-data-[theme=Heartland]/root:bg-primary group-data-[theme=Heartland]/root:text-base-000':
                            historicalWeatherData === data.value,
                          'text-base-800': historicalWeatherData !== data.value,
                        })}
                        onClick={() => setHistoricalWeatherData(data.value)}
                      >
                        {data.label}
                      </span>
                    ))}
                  </div>
                </CardHeader>
                <p className="text-base-500">
                  Average Temperature Over The Last 10 Years On This Date
                </p>
                <p className="text-lg-bold mt-1">2014 - 2023</p>
                <div>
                  <SingleLineChart
                    data={[65, 62, 80, 70, 75, 78, 72, 68, 70, 75]}
                    labels={[
                      '2014',
                      '2015',
                      '2016',
                      '2017',
                      '2018',
                      '2019',
                      '2020',
                      '2021',
                      '2022',
                      '2023',
                    ]}
                    width={572}
                    height={152}
                    stepSize={1000}
                    scales={{
                      x: {
                        time: {
                          unit: 'year',
                          displayFormats: {
                            year: 'YYYY',
                          },
                        },
                        grid: {
                          display: false,
                        },
                        ticks: {
                          color: lineChartColorsByNetwork(user?.network_partner)
                            ?.scalesColor,
                          font: {
                            family: 'RobotoCondensed',
                          },
                        },
                      },
                    }}
                    backgroundColor={lineChartColorsByNetwork(
                      user?.network_partner
                    )}
                    borderColor={
                      lineChartColorsByNetwork(user?.network_partner)
                        ?.borderColor
                    }
                    scalesColor={
                      lineChartColorsByNetwork(user?.network_partner)
                        ?.scalesColor
                    }
                    yTicksCallback={(value) => `${value}°`}
                  />
                </div>
              </div>
              <div className="card p-6 flex justify-between items-center gap-4">
                <div className="space-y-2">
                  <CardHeader title="Past Storm Events" tooltip="demo" />
                  <p className="text-base-500">
                    Last Spring there were 40 flood, 20 winter weather, and 10
                    strong wind storm events in Brookings, South Dakota.
                  </p>
                </div>
                <span>
                  <Button color="primary" size="lg">
                    View Past Storm Events
                  </Button>
                </span>
              </div>
            </div>
            <div className="card col-span-3 p-4 py-5">
              <CardHeader title="Agricultural Weather Elements" />
              <table className="mt-3">
                {agriWeatherElements.map((data) => (
                  <tr key={data.label} className="text-lg-bold">
                    <td className="py-3">{data.icon}</td>
                    <td className="px-6 py-3">{data.value}</td>
                    <td className="py-3">{data.label}</td>
                  </tr>
                ))}
              </table>
            </div>
          </div>*/}
        </div> 
      </div>
    </WeatherContext.Provider>
  );
}
