export const optionTypeOptions = [
  { label: 'Call', value: 'call' },
  { label: 'Put', value: 'put' },
];

export const watchTypeOptions = [
  { value: 'cash', label: 'Cash' },
  { value: 'futures', label: 'Futures' },
  { value: 'options', label: 'Options' },
  { value: 'basis', label: 'Basis' },
  { value: 'spread', label: 'Spread' },
];

export const areaSelectionOptions = [
  { label: 'All Corn Fields', value: 'Corn' },
  // { label: 'All HRW Wheat Fields', value: 'Wheat' },
  // { label: 'All SRW Wheat Fields', value: 'Wheat' },
  { label: 'All Soybean Fields', value: 'Soybeans' },
];

export const mapRelationshipOptions = [
  { label: 'Variety', value: 'variety' },
  { label: 'Seed Yield', value: 'seed_yield' },
  { label: 'Flow', value: 'flow' },
  // { label: 'Yield', value: 'yield' },
  // { label: 'Yield-Performance', value: 'yield_performance' },
  { label: 'Seeding Rate (Seeds per Acre)', value: 'seeding_rate' },
  { label: 'OM (%)', value: 'OM' },
  { label: 'PH', value: 'pH' },
  { label: 'Sand (%)', value: 'sand' },
  { label: 'Silt (%)', value: 'silt' },
  { label: 'CEC (Milliequivalents)', value: 'CEC_meq' },
  { label: 'Elevation', value: 'elev' },
  { label: 'Slope', value: 'slope' },
  { label: 'Clay (%)', value: 'clay' },
  // { label: 'K_ppm', value: 'k_ppm'},
  // { label: 'Na_ppm', value: 'na_ppm'},
  { label: 'Field Yield', value: 'field_name' },
  { label: 'Zone Yield', value: 'agrivar_zone' },
];

export const mapTabOptions = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Revenue',
    value: 'revenue',
  },
  {
    label: 'Net Income',
    value: 'net_income',
  },
  {
    label: 'Yield',
    value: 'yield',
  },
  {
    label: 'Yield Performance',
    value: 'yield_performance',
  },
  {
    label: 'Yield Probability',
    value: 'yield_probability',
  },
  {
    label: 'Yield Error',
    value: 'yield_error',
  },
  {
    label: 'Risk',
    value: 'sigma',
  },
  {
    label: 'Satellite',
    value: 'ndvi_s2',
  },
  {
    label: 'Satellite Deviation',
    value: 'ndvi_pct',
  },
  {
    label: 'Hi-Resolution Satellite',
    value: 'hiResolutionSatellite',
  },
];

export const optionFilters = [
  {
    label: 'All',
    value: 'all',
  },
  {
    label: 'Call',
    value: 'call',
  },
  {
    label: 'Put',
    value: 'put',
  },
  {
    label: 'In the Money',
    value: 'ITM',
  },
  {
    label: 'Out of the Money',
    value: 'OTM',
  },
  {
    label: 'Near the Money',
    value: 'NTM',
  },
];
