import { ArrowDownIcon, ArrowUpIcon } from '@heroicons/react/24/solid';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { CodeToCrop } from '../../../constants';
import { useMarketContext } from '../../../contexts/market-context';
import { useWebsocketContext } from '../../../contexts/websocket-context';
import { useAllFutures } from '../../../hooks/market/use-futures';
import { useMarketBidAsk } from '../../../hooks/market/use-market';
import { useWebSocketDataV2 } from '../../../hooks/websocket/use-websocket-data';
import { AllFuturesRecord } from '../../../types';
import {
  convertSymbolToContract,
  getTime,
  parseContractDate,
} from '../../../utils';
import Table, { TableConfigs } from '../../commons/table/Table';
import { commodityOptions } from './PriceChart';
import TableLayout from '../commons/TableLayout';

function AllFuturesTable() {
  const [highlightedCells, setHighlightedCells] = useState<
    { rowKey: string; columnKey: string }[]
  >([]);

  const contractTableConfigs: TableConfigs = {
    cols: [
      {
        key: 'contract',
        name: 'Contract',
      },
      {
        key: 'low',
        name: 'Low',
      },
      {
        key: 'high',
        name: 'High',
      },
      {
        key: 'last',
        name: 'Last',
      },
      {
        key: 'change',
        name: 'Change',
        type: 'fluctuation',
      },
      // {
      //   key: 'bid',
      //   name: 'Bid',
      // },
      // {
      //   key: 'ask',
      //   name: 'Ask',
      // },
      {
        key: 'volume',
        name: 'Volume',
      },
      {
        key: 'openInterest',
        name: 'Open Interest',
      },
      {
        key: 'previous',
        name: 'Open',
      },
      {
        key: 'IV',
        name: 'IV',
      },
      {
        key: 'IV_change',
        name: 'IV Change',
      },
      {
        key: 'lastTradeTime',
        name: 'Last Trade',
      },
    ],
  };

  const { pricesSymbolRef, pricesCropRef, trigger, setTrigger } =
    useMarketContext();

  const futures = useAllFutures(pricesSymbolRef.current.slice(0, 2));

  const bidAsk = useMarketBidAsk({ symbol: pricesSymbolRef.current });
  const [loadedTableData, setLoadedTableData] = useState<boolean>(false);

  const {
    roots,
    setRoots,
    tables,
    setTables,
    productTypes,
    setProductTypes,
    year,
    setYear,
  } = useWebsocketContext();

  const msgHLCO: any = useWebSocketDataV2('HLCO');
  const msgBidAsk: any = useWebSocketDataV2('Bid_Ask');

  useEffect(() => {
    setRoots([pricesSymbolRef.current.slice(0, 2)]);
  }, [trigger]);

  const [tableData, setTableData] = useState<AllFuturesRecord[]>([]);
  const [currentTableDataRoot, setCurrentTableDataRoot] = useState<string>('');

  useEffect(() => {
    let mapData: AllFuturesRecord[] = [];
    const newHighlights: { rowKey: string; columnKey: string }[] = [];

    if (
      pricesSymbolRef.current.slice(0, 2) === currentTableDataRoot &&
      tableData &&
      tableData.length > 0
    ) {
      mapData = tableData; // Use the existing tableData
    } else {
      mapData = futures.data; // Initialize with futures data
      setLoadedTableData(true); // Mark as loaded
    }

    const updatedData = mapData
      ?.map((item: AllFuturesRecord) => {
        if (item && item.symbol && item.symbol.length === 4) {
          let newHLCO = {};
          let newBidAsk = {};
          let isNewMessage = false;
          let columnUpdated = false;

          const contractDetails = convertSymbolToContract(item.symbol);
          if (!contractDetails) return null;

          const { month, year } = contractDetails;
          const contract = `${month} '${year}`;

          if (msgHLCO && msgHLCO.data.symbol === item.symbol) {
            newHLCO = msgHLCO.data;
            isNewMessage = true; // Only trigger highlight on HLCO message
            if (
              item.high !== msgHLCO.data.high &&
              msgHLCO.data.high !== undefined
            ) {
              columnUpdated = true;
              newHighlights.push({ rowKey: contract, columnKey: 'high' });
            }
            if (
              item.low !== msgHLCO.data.low &&
              msgHLCO.data.low !== undefined
            ) {
              columnUpdated = true;
              newHighlights.push({ rowKey: contract, columnKey: 'low' });
            }
            if (
              item.last !== msgHLCO.data.last &&
              msgHLCO.data.last !== undefined
            ) {
              columnUpdated = true;
              newHighlights.push({ rowKey: contract, columnKey: 'last' });
            }
            if (
              item.change !== msgHLCO.data.change &&
              msgHLCO.data.change !== undefined
            ) {
              columnUpdated = true;
              newHighlights.push({ rowKey: contract, columnKey: 'change' });
            }
          }

          if (msgBidAsk && msgBidAsk.data.symbol === item.symbol) {
            newBidAsk = msgBidAsk.data;
            isNewMessage = true; // Trigger highlight on BidAsk only if no HLCO for this symbol
            if (
              item.bid !== msgBidAsk.data.bid &&
              msgBidAsk.data.bid !== undefined
            ) {
              columnUpdated = true;
              newHighlights.push({ rowKey: contract, columnKey: 'bid' });
            }
            if (
              item.change !== msgBidAsk.data.change &&
              msgBidAsk.data.change !== undefined
            ) {
              columnUpdated = true;
              newHighlights.push({ rowKey: contract, columnKey: 'change' });
            }
            if (
              item.volume !== msgBidAsk.data.volume &&
              msgBidAsk.data.volume !== undefined
            ) {
              columnUpdated = true;
              newHighlights.push({ rowKey: contract, columnKey: 'volume' });
            }
            if (
              item.openInterest !== msgBidAsk.data.open_interest &&
              msgBidAsk.data.open_interest !== undefined
            ) {
              columnUpdated = true;
              item.openInterest = msgBidAsk.data.open_interest;
              newHighlights.push({
                rowKey: contract,
                columnKey: 'openInterest',
              });
            }
            if (
              item.last !== msgBidAsk.data.trade_pice &&
              msgBidAsk.data.trade_price !== undefined
            ) {
              columnUpdated = true;
              item.last = msgBidAsk.data.trade_price;
              newHighlights.push({ rowKey: contract, columnKey: 'last' });
            }
            if (
              item.IV !== msgBidAsk.data.implied_volatility &&
              msgBidAsk.data.implied_volatility !== undefined
            ) {
              item.IV = msgBidAsk.data.implied_volatility;
              columnUpdated = true;
              newHighlights.push({ rowKey: contract, columnKey: 'IV' });
            }
            if (
              item.IV_change !== msgBidAsk.data.implied_volatility_chg &&
              msgBidAsk.data.implied_volatility_chg !== undefined
            ) {
              item.IV_change = msgBidAsk.data.implied_volatility_chg;
              columnUpdated = true;
              newHighlights.push({ rowKey: contract, columnKey: 'IV_change' });
            }
            if (
              item.ask !== msgBidAsk.data.ask &&
              msgBidAsk.data.ask !== undefined
            ) {
              columnUpdated = true;
              newHighlights.push({ rowKey: contract, columnKey: 'ask' });
            }
          }

          const date = moment(
            `${month} 1, 20${year}`,
            'MMM D, YYYY'
          ).toISOString();

          return {
            ...item,
            contract,
            lastTradeTime: getTime(item.last_trade_time),
            date: date,
            ...newHLCO,
            ...newBidAsk,
          };
        }
        return null;
      })
      .filter(
        (value: any): value is AllFuturesRecord =>
          value !== null && value !== undefined
      )
      .filter((value: AllFuturesRecord) => moment(value.date).isAfter(moment()))
      .sort((a: AllFuturesRecord, b: AllFuturesRecord) => {
        const dateA = parseContractDate(a.contract);
        const dateB = parseContractDate(b.contract);
        return dateA.getTime() - dateB.getTime();
      });

    setTableData(updatedData);
    setCurrentTableDataRoot(pricesSymbolRef.current.slice(0, 2));

    if (newHighlights.length > 0) {
      setHighlightedCells((prev) =>
        Array.from(new Set([...prev, ...newHighlights]))
      );
    }
  }, [futures.data, msgHLCO.data, msgBidAsk.data]);

  useEffect(() => {
    // Remove highlights after the specified duration
    const timeout = setInterval(() => {
      setHighlightedCells([]);
    }, 500);

    // Cleanup timeout if component unmounts or dependencies change
    return () => clearTimeout(timeout);
  }, []);

  return (
    <div className="card relative overflow-hidden">
      <TableLayout
        icon={
          commodityOptions.filter(
            (item: any) => pricesCropRef.current === item.value
          )[0].icon
        }
        title={`
          All ${
            CodeToCrop[
              pricesSymbolRef.current.slice(0, 2) as keyof typeof CodeToCrop
            ]
          } Futures`}
      >
        <Table
          configs={contractTableConfigs}
          data={tableData}
          loading={futures.isLoading || !loadedTableData}
          highlightCells={highlightedCells}
          indexKey="contract"
          pagination={{
            size: 15,
          }}
        />
      </TableLayout>
    </div>
  );
}

export default AllFuturesTable;
