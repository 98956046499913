import jsPDF from 'jspdf';
import autoTable from 'jspdf-autotable';
import Papa from 'papaparse';
import FileDownloadIcon from '../../../assets/icons/FileDownloadIcon';
import IconButton from '../inputs/IconButton';
import Menu from '../menu/Menu';

type DownloadButtonProps = {
  data: any[];
};

export default function TableDownloadButton({ data }: DownloadButtonProps) {
  // Handle download
  const downloadCSV = () => {
    const csv = Papa.unparse(data);
    const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });
    const link = document.createElement('a');
    link.href = URL.createObjectURL(blob);
    link.download = 'inbound-grain.csv';
    link.click();
  };

  // Handle download PDF
  const downloadPDF = () => {
    const csv = Papa.unparse(data);
    const doc = new jsPDF();
    const rows = csv.split('\n').map((row) => row.split(','));
    autoTable(doc, {
      head: [rows[0]],
      body: rows.slice(1),
      styles: {
        cellWidth: 'wrap',
      },
    });
    doc.save('inbound-grain.pdf');
  };

  const items = [
    {
      value: 'csv',
      label: 'Download CSV',
      onClick: downloadCSV,
    },
    {
      value: 'pdf',
      label: 'Download PDF',
      onClick: downloadPDF,
    },
    {
      value: 'email',
      label: 'Send to Email',
      onClick: () => {},
    },
  ];

  return (
    <Menu items={items}>
      <IconButton className="w-8" color="primary">
        <FileDownloadIcon fill="white" />
      </IconButton>
    </Menu>
  );
}
