import { useMemo, useState } from 'react';
import Button from '../../../components/commons/Button';
import CropIcon from '../../../components/commons/CropIcon';
import Disclosure from '../../../components/commons/Disclosure';
import Select, { AwaitSelect } from '../../../components/commons/Select';
import ExportButton from '../../../components/dashboard/prescription/ExportButton';
import PrescriptionMapContainer from '../../../components/dashboard/prescription/PrescriptionMapContainer';
import AddChemicals from '../../../components/dashboard/prescription/add-chemicals';
import AddFertilizer from '../../../components/dashboard/prescription/add-fertilizer';
import Logs from '../../../components/dashboard/prescription/logs';
import SetSeedingRateAndVarieties from '../../../components/dashboard/prescription/set-seeding-rate-and-varieties';
import SetYieldGoal from '../../../components/dashboard/prescription/set-yield-goal';
import Subtotal from '../../../components/dashboard/prescription/subtotals';
import { SupportedCrops } from '../../../constants';
import { useYearFilter } from '../../../contexts/app-filter-context';
import { useMainUser, useUser } from '../../../contexts/auth-context';
import { useFieldPolygonsByYear } from '../../../hooks/field/use-field-polygons';
import {
  useFieldPrescription,
  useResetFieldPrescription,
} from '../../../hooks/field/use-field-prescription';

export const Fertilizers = [
  {
    id: 'N',
    name: 'Nitrogen',
  },
  {
    id: 'P',
    name: 'Phosphorus',
  },
  {
    id: 'K',
    name: 'Potassium',
  },
];

export const Micronutrients = [
  {
    id: 'Zn',
    name: 'Zinc',
  },
  {
    id: 'Fe',
    name: 'Iron',
  },
  {
    id: 'Cu',
    name: 'Copper',
  },
  { id: 'Mn', name: 'Manganese' },
  { id: 'B', name: 'Boron' },
  {
    id: 'Mo',
    name: 'Molybdenum',
  },
  {
    id: 'Cl',
    name: 'Chlorine',
  },
  {
    id: 'Ca',
    name: 'Calcium',
  },
  {
    id: 'S',
    name: 'Sulfur',
  },
  {
    id: 'Mg',
    name: 'Magnesium',
  },
];

const selectLabelClasses =
  'absolute z-[1] top-[50%] -translate-y-[50%] left-4 text-xl-bold';

export default function Prescription() {
  const [crop, setCrop] = useState(SupportedCrops[0]);
  const year = useYearFilter();
  const user = useUser();
  const mainUser = useMainUser();
  const userIdYear = `${user?.id}_${year}`;
  const { data: fields, isLoading } = useFieldPolygonsByYear(
    user?.id || '',
    year || ''
  );
  const { mutate: reset } = useResetFieldPrescription();

  const fieldOptions = fields?.map((field) => ({
    value: `${field.field_index}_${field.field}`,
    label: field.field,
    icon: <CropIcon cropName={field.crop2} />,
  }));
  fieldOptions?.sort((a, b) => a.label.localeCompare(b.label));

  const [selectedField, setSelectedField] = useState(fieldOptions?.[0]?.value);
  const [fieldIndex, field] = selectedField?.split('_') ?? ['', ''];
  const { data, isFetching } = useFieldPrescription(userIdYear, fieldIndex);

  const filteredPrescriptions = useMemo(() => {
    return (
      data
        ?.filter((item) => item.crop === crop)
        ?.sort(
          (a, b) => parseInt(a.agrivar_zone1) - parseInt(b.agrivar_zone1)
        ) || []
    );
  }, [data, crop]);

  const handleReset = () => {
    reset({
      user_id_crop: `${user?.id}_${year}-${crop}`,
      agrivar_zones: filteredPrescriptions.map(
        (prescription) => prescription.agrivar_zone
      ),
      user: {
        year: year || '',
        field,
        field_index: fieldIndex,
        id: mainUser?.id || '',
        username: mainUser?.username || '',
      },
    });
  };

  return (
    <div className="space-y-8">
      {/* Header */}
      <div className="flex justify-between items-center">
        <div className="flex gap-4">
          <div className="relative">
            <p className={selectLabelClasses}>Field:</p>
            {fieldOptions?.length > 0 && (
              <AwaitSelect
                options={fieldOptions}
                name={'field'}
                className="rounded-xl px-5 py-3 pl-[72px] text-xl-bold"
                isLoading={isLoading}
                onChange={(e) => setSelectedField(e.target.value)}
                defaultValue={fieldOptions[0].value}
              />
            )}
          </div>

          <div className="relative">
            <p className={selectLabelClasses}>Crop:</p>
            <Select
              options={SupportedCrops.map((crop) => ({
                value: crop,
                label: crop,
                icon: <CropIcon cropName={crop} />,
              }))}
              name={'crop'}
              defaultValue={SupportedCrops[0]}
              className="rounded-xl px-5 py-3 pl-[72px] text-xl-bold"
              onChange={(e) => setCrop(e.target.value)}
            />
          </div>

          <Button
            className="!w-fit shadow-lg bg-base-900"
            size="lg"
            onClick={handleReset}
          >
            Reset
          </Button>
        </div>

        <ExportButton
          crop={crop}
          fieldIndex={fieldIndex}
          userIdYear={filteredPrescriptions?.[0]?.user_id}
        />
      </div>

      {/* Map */}
      <PrescriptionMapContainer
        prescriptions={filteredPrescriptions}
        crop={crop}
        field={fieldIndex}
      />

      {/* Disclosures */}
      <Disclosure title="Set Yield Goal">
        <SetYieldGoal
          crop={crop}
          prescriptions={filteredPrescriptions}
          loading={isFetching}
        />
      </Disclosure>
      <Disclosure title="Set Seeding Rate and Varieties">
        <SetSeedingRateAndVarieties
          presctiptions={filteredPrescriptions}
          loading={isFetching}
          crop={crop}
        />
      </Disclosure>
      <Disclosure title="Add Fertilizer">
        {Fertilizers.map((fertilizer) => (
          <AddFertilizer
            key={fertilizer.name}
            inputType="fertilizer"
            fertilizer={fertilizer}
            crop={crop}
            presctiptions={filteredPrescriptions}
            loading={isFetching}
          />
        ))}
      </Disclosure>
      <Disclosure title="Add Chemicals">
        <AddChemicals
          crop={crop}
          presctiptions={filteredPrescriptions}
          loading={isFetching}
        />
      </Disclosure>
      <Disclosure title="Add Micronutrients">
        {Micronutrients.map((fertilizer) => (
          <AddFertilizer
            key={fertilizer.name}
            inputType="micronutrients"
            fertilizer={fertilizer}
            crop={crop}
            presctiptions={filteredPrescriptions}
            loading={isFetching}
          />
        ))}
      </Disclosure>
      <Disclosure title="Prescription Subtotals">
        <Subtotal crop={crop} fieldIndex={fieldIndex} />
      </Disclosure>
      <Disclosure title="Logs">
        <Logs field={fieldIndex} />
      </Disclosure>
    </div>
  );
}
