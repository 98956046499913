import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { useUser } from '../../contexts/auth-context';
import {
  useHistoricalInsights,
  useTableData,
} from '../../hooks/historical-insights/use-historical-insights';
import { NETWORK_PARTNER } from '../../constants';
import { theme } from 'twin.macro';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);

const getColors = (partner?: string) => {
  switch (partner) {
    case NETWORK_PARTNER.heartland:
      return '#33000E';
    default:
      return 'white';
  }
};

const HorizontalBar = () => {
  const user = useUser();
  const { year, relationship, crop, page, yieldChartRef } =
    useHistoricalInsights();
  const tableData = useTableData(user?.id || '', crop, relationship, year);

  const data = {
    labels: tableData.data
      ?.slice(page * 5, (page + 1) * 5)
      .map((data: any) => data.x_bin),
    datasets: [
      {
        label: 'Yield',
        data: tableData.data
          ?.slice(page * 5, (page + 1) * 5)
          .map((data: any) => data.yield),
        backgroundColor: tableData.data
          ?.slice(page * 5, (page + 1) * 5)
          .map((data: any) => `rgba(${data.x_r}, ${data.x_g}, ${data.x_b}, 1)`),
        borderColor: tableData.data
          ?.slice(page * 5, (page + 1) * 5)
          .map((data: any) => `rgba(${data.x_r}, ${data.x_g}, ${data.x_b}, 1)`),
        borderWidth: 1,
        borderRadius: 5, // Rounded bars
        barThickness: 25, // Thickness of the bars
      },
    ],
  };

  const options: any = {
    layout: {
      padding: {
        left: 0,
        right: 80,
        top: 0,
        bottom: 0,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'y',
    scales: {
      x: {
        border: {
          color: 'transparent', // Make x-axis line transparent
        },
        beginAtZero: true,
        ticks: {
          display: false,
        },
        grid: {
          display: false, // Hide the grid lines
        },
        title: {
          display: true,
          text: 'Bu/Ac',
          font: { size: 14 },
        },
      },
      y: {
        border: {
          color: 'transparent', // Make x-axis line transparent
        },
        title: {
          display: true,
          text: `${relationship.charAt(0).toUpperCase() + relationship.slice(1)}`,
          font: { size: 14 },
        },
        ticks: {
          font: {
            size: 14,
            weight: 'bold',
            color: '#ffffff',
          },
        },
        grid: {
          display: false, // Hide the grid lines
        },
      },
    },
    plugins: {
      title: {
        display: true,
        text: `${year} Yield`,
        font: { size: 20 },
        color: getColors(user?.network_partner),
      },
      legend: {
        display: false,
      },
      datalabels: {
        anchor: 'end',
        align: 'end',
        formatter: (value: any) => `${parseFloat(value).toFixed(2)} Bu/Ac`,
        color: user?.network_partner === 'Heartland' ? 'black' : 'white',
        font: {
          size: 12,
          weight: 'bold',
        },
      },
    },
    elements: {
      bar: {
        hoverBackgroundColor: tableData.data
          ?.slice(page * 5, (page + 1) * 5)
          .map((data: any) => `rgba(${data.x_r}, ${data.x_g}, ${data.x_b}, 1)`),
      },
    },
  };

  return (
    <div style={{ width: '100%', height: '100%', margin: '0 auto' }}>
      <Bar
        ref={yieldChartRef}
        data={data}
        options={options}
        plugins={[ChartDataLabels]}
      />
    </div>
  );
};

export default HorizontalBar;
