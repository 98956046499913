import { useEffect, useState } from 'react';
import { getTimeSet } from '../../utils';

export const useTimeSet = (s3Keys: string[], type: string) => {
  const [timeSet, setTimeSet] = useState<any[]>([]);

  useEffect(() => {
    if (type !== '24hrPrecipitation') {
      
      const result = getTimeSet(s3Keys);
      if (result.length > 0) {
        setTimeSet(result);
      } else {
        // create a timeset of 24 hours in format 00:00, 09:13, 15:55, etc
        const times = Array.from({ length: 24 }, (_, i) => {
          const date = new Date();
          date.setHours(i);
          return date.getHours() < 10 ? `0${date.getHours()}:00` : `${date.getHours()}:00`;
        });
        console.log('times', times);
        setTimeSet(times);
      }
    } else {
      // parse dates
      const dates = s3Keys.map((key) => {
        const date = key.split('_')[1];
        return date;
      });
      console.log('dates', dates);
      if (dates.length > 0) {
        setTimeSet(dates);
      } else {
        // create a timeset of 24 hours in format 00:00, 09:13, 15:55, etc
        const times = Array.from({ length: 24 }, (_, i) => {
          const date = new Date();
          date.setHours(i);
          return date.getHours() < 10 ? `0${date.getHours()}:00` : `${date.getHours()}:00`;
        });
        console.log('times', times);
        setTimeSet(times);
      }
    }
  }, [s3Keys, type]);

  return {
    timeSet,
  };
};
