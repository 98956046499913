import { PlusCircleIcon } from '@heroicons/react/20/solid';
import { enqueueSnackbar } from 'notistack';
import { ReactNode, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useYearFilter } from '../../../../contexts/app-filter-context';
import { useUser } from '../../../../contexts/auth-context';
import { useGetChemicalProducts } from '../../../../hooks/chemical/use-chemical';
import {
  useCreatePrescriptionProduct,
  useUpdatePrescriptionProduct,
} from '../../../../hooks/field/use-field-prescription';
import {
  FieldPrescriptionWithProducts,
  PrescriptionProductRes,
} from '../../../../types';
import { AddChemicalPayload } from '../../../../types/prescriptions';
import Button from '../../../commons/Button';
import CardHeader from '../../../commons/CardHeader';
import Select, { AwaitSelect } from '../../../commons/Select';
import TextInput from '../../../commons/inputs/TextInput';
import { applicationTimeOptions } from '../../../../constants';
import ComboSelectInput from '../../../commons/inputs/ComboSelectInput';

type ChemicalFormProps = {
  title?: string | ReactNode;
  crop: string;
  selectedZone: FieldPrescriptionWithProducts;
  edit?: boolean;
  setIsEditing?: (value: boolean) => void;
  selectedProduct?: PrescriptionProductRes;
  zones: FieldPrescriptionWithProducts[];
  onFlip?: () => void;
};

export default function ChemicalForm({
  selectedZone,
  crop,
  zones,
  title,
  setIsEditing,
  selectedProduct,
  edit,
  onFlip,
}: ChemicalFormProps) {
  const form = useForm({
    defaultValues: edit
      ? {
          ...selectedProduct,
        }
      : {},
  });
  const user = useUser();
  const year = useYearFilter();

  const { data: products, isFetching: isFetchingChemicalProducts } =
    useGetChemicalProducts(user?.network_partner ?? '');
  const productOptions = useMemo(() => {
    return products?.map((product) => ({
      label: product.itemdescription,
      value: product.itemdescription,
    }));
  }, [products]);

  const { mutateAsync: create, isPending: isCreating } =
    useCreatePrescriptionProduct();

  const { mutateAsync: update, isPending: isUpdating } =
    useUpdatePrescriptionProduct();

  const handleCreate = async ({
    total_cost,
    total_units,
    cost_per_acre,
    applyAllZones,
    rate_per_acre,
    ...values
  }: any) => {
    if (!year || !user) return;
    const appliedZones = form.watch('applyAllZones')
      ? zones.map((zone) => {
          return {
            zone: zone.agrivar_zone,
            acres: zone.area,
            total_cost: '0',
            total_units: '0',
            cost_per_acre: cost_per_acre.toString(),
            rate_per_acre: rate_per_acre.toString(),
          };
        })
      : [
          {
            zone: selectedZone.agrivar_zone,
            acres: selectedZone.area,
            total_cost: total_cost.toString(),
            total_units: total_units.toString(),
            cost_per_acre: cost_per_acre.toString(),
            rate_per_acre: rate_per_acre.toString(),
          },
        ];
    const payload: AddChemicalPayload = {
      ...values,
      zone: appliedZones,
      crop: crop,
      year: year,
      userId: user.id,
      input_type: 'chemicals',
      field_index: selectedZone.field_index,
      field: selectedZone.field,
    };

    try {
      await create(payload);
      setIsEditing?.(false);
      enqueueSnackbar('Chemical added successfully', { variant: 'success' });
      onFlip?.();
    } catch (error) {
      enqueueSnackbar('Failed to add chemical', { variant: 'error' });
      console.error(error);
    }
  };

  const handleUpdate = async (values: any) => {
    if (!selectedProduct) return;
    try {
      await update({
        ...selectedProduct,
        ...Object.fromEntries(
          Object.entries(values).map(([key, value]) => [key, String(value)])
        ),
        input_type: 'chemicals',
        area: selectedZone.area,
        user_id: `${user?.id}_${year}`,
        user_id_agrivar_zone: `${user?.id}_${year}-${selectedZone.agrivar_zone}`,
        crop: crop,
        field: selectedZone.field,
        field_index: selectedZone.field_index,
      });
      setIsEditing?.(false);
      enqueueSnackbar('Updated successfully', {
        variant: 'success',
      });
      onFlip?.();
    } catch (error) {
      enqueueSnackbar('Something went wrong, please try again later', {
        variant: 'error',
      });
    }
  };

  const product = form.watch('product');
  useEffect(() => {
    if (product) {
      const productData = products?.find(
        (item) => item.itemdescription === product
      );
      form.setValue('brand', productData?.brand);
      form.setValue('price_per_unit', productData?.price);
      form.setValue('total_units', '0');
      form.setValue('total_cost', '0');
      form.setValue('cost_per_acre', '0');
    }
  }, [products, product]);

  return (
    <div className="space-y-8">
      <CardHeader
        title={title ?? 'Chemicals (lbs. Per Acre)'}
        tooltip={'tooltip'}
      />
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(edit ? handleUpdate : handleCreate)}
          className="space-y-4"
        >
          <ComboSelectInput
            label="Select Product"
            name="product"
            options={productOptions}
            required
          />
          <TextInput name="brand" label="Brand Name" required />
          <TextInput
            name="price_per_unit"
            label="Price Per Unit"
            type="number"
            required
          />
          <TextInput
            name="rate_per_acre"
            label="Rate Per Acre"
            type="number"
            placeholder="Please enter rate per acre"
            required
          />
          <TextInput
            name="total_units"
            label="Total Units"
            type="number"
            required
          />
          <TextInput
            name="total_cost"
            label="Total Cost"
            type="number"
            required
          />
          <Select
            name="application_time"
            label="Select Application Period"
            options={applicationTimeOptions}
            required
          />
          {!edit && (
            <div className="flex space-x-4">
              <Button disabled={isUpdating} onClick={() => onFlip?.()}>
                Cancel
              </Button>
              <Button
                type="submit"
                color="primary"
                startIcon={<PlusCircleIcon className="size-6" />}
                shadow
                disabled={isCreating || !form.formState.isValid}
              >
                Add To Cheminal
              </Button>
            </div>
          )}

          {edit && (
            <div className="flex space-x-4">
              <Button
                disabled={isUpdating}
                onClick={() => setIsEditing?.(false)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="primary"
                disabled={isUpdating || !form.formState.isValid}
                loading={isUpdating}
              >
                Update
              </Button>
            </div>
          )}
        </form>
      </FormProvider>
    </div>
  );
}
