import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { NETWORK_PARTNER } from '../../constants';
import { useUser } from '../../contexts/auth-context';
import {
  useHistoricalInsights,
  useTableData,
} from '../../hooks/historical-insights/use-historical-insights';
import ChartDataLabels from 'chartjs-plugin-datalabels';

ChartJS.register(
  BarElement,
  CategoryScale,
  LinearScale,
  Title,
  Tooltip,
  Legend
);

const getColors = (partner?: string) => {
  switch (partner) {
    case NETWORK_PARTNER.heartland:
      return '#33000E';
    default:
      return 'white';
  }
};

const PercentageHorizontalBar = () => {
  const user = useUser();
  const { year, relationship, crop, page, yieldPerformanceChartRef } =
    useHistoricalInsights();
  const tableData = useTableData(user?.id || '', crop, relationship, year);

  const data = {
    labels: tableData.data
      ?.slice(page * 5, (page + 1) * 5)
      .map((data: any) => data.x_bin),
    datasets: [
      {
        label: 'Yield Performance',
        data: tableData.data
          ?.slice(page * 5, (page + 1) * 5)
          .map((data: any) => data.yield_performance),
        backgroundColor: tableData.data
          ?.slice(page * 5, (page + 1) * 5)
          .map((data: any) => `rgba(${data.x_r}, ${data.x_g}, ${data.x_b}, 1)`),
        borderColor: tableData.data
          ?.slice(page * 5, (page + 1) * 5)
          .map((data: any) => `rgba(${data.x_r}, ${data.x_g}, ${data.x_b}, 1)`),
        borderWidth: 1,
        borderRadius: 5, // Rounded bars
        barThickness: 25, // Thickness of the bars
      },
    ],
  };

  const options: any = {
    layout: {
      padding: {
        left: 0,
        right: 30,
        top: 0,
        bottom: 0,
      },
    },
    responsive: true,
    maintainAspectRatio: false,
    indexAxis: 'y',
    scales: {
      x: {
        beginAtZero: true,
        ticks: {
          display: false,
        },
        grid: {
          display: false, // Hide the grid lines
        },
        title: {
          display: true,
          text: 'Bu/Ac',
          font: { size: 14 },
        },
        border: {
          color: 'transparent', // Make x-axis line transparent
        },
      },
      y: {
        border: {
          color: 'transparent', // Make x-axis line transparent
        },
        title: {
          display: false,
          text: `${relationship.charAt(0).toUpperCase() + relationship.slice(1)}`,
          font: { size: 14 },
        },
        ticks: {
          callback: (value: string) => {
            console.log('value', value);
            const label = data?.labels[parseInt(value)]
            // Manually wrap the text by splitting at a specific character count
            if (label.length > 5) {
              return label.slice(0, 5) + '... ';
            } 
            return label;
          },
          font: {
            size: 14,
            weight: 'bold',
            color: '#ffffff',
          },
          padding: 60,
        },
        grid: {
          display: false, // Hide the grid lines
        },
      },
    },
    plugins: {
      tooltip: {
        enabled: false, // Disable default tooltips
        external: function (context: any) {
          const tooltipModel = context.tooltip;
          // Check if tooltip should be shown
          if (tooltipModel.opacity === 0) {
            const tooltipEl = document.getElementById('custom-tooltip');
            if (tooltipEl) {
              tooltipEl.style.opacity = '0';
            }
            return
          }

          // Show tooltip with full label
          const label = context.chart.data.labels[tooltipModel.dataPoints[0].dataIndex];
          const tooltipEl = document.getElementById('custom-tooltip');
          tooltipEl!.innerHTML = label;
          tooltipEl!.style!.opacity = '1';
          tooltipEl!.style.position = 'absolute';
          tooltipEl!.style.left = tooltipModel.caretX + 'px';
          tooltipEl!.style.top = tooltipModel.caretY + 'px';
        },
      },
      title: {
        display: true,
        text: `${year} Yield Performance`,
        font: { size: 20 },
        color: getColors(user?.network_partner),
      },
      legend: {
        display: false,
      },
      datalabels: {
        anchor: (context: any) => {
          console.log('context', context);
          console.log(
            context.dataset.data[context.dataIndex],
            context.dataset[context.dataIndex] >= 0
          );
          return parseFloat(context.dataset.data[context.dataIndex]) >= 0
            ? 'end'
            : 'start'; // Anchor right for positive, left for negative
        },
        align: (context: any) =>
          parseFloat(context.dataset.data[context.dataIndex]) >= 0
            ? 'end'
            : 'start', // Anchor right for positive, left for negative
        formatter: (value: any) => `${parseFloat(value).toFixed(2)} Bu/Ac`,
        color: (context: any) => {
          const value = parseFloat(context.dataset.data[context.dataIndex]);
          if (value < 0) return 'red'; // Red for negative values
          if (value > 0) return 'green'; // Green for positive values
          return 'white'; // White for zero
        },
        font: {
          size: 12,
          weight: 'bold',
        },
      },
    },
    elements: {
      bar: {
        hoverBackgroundColor: tableData.data
          ?.slice(page * 5, (page + 1) * 5)
          .map((data: any) => `rgba(${data.x_r}, ${data.x_g}, ${data.x_b}, 1)`),
      },
    },
  };

  return (
    <div style={{ width: '100%', height: '100%', margin: '0 auto' }}>
      <Bar
        ref={yieldPerformanceChartRef}
        data={data}
        options={options}
        plugins={[ChartDataLabels]}
      />
            <div id="custom-tooltip" style={{ opacity: 0, transition: 'opacity 0.2s' }}></div>

    </div>
  );
};

export default PercentageHorizontalBar;
