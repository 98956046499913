import moment from 'moment';
import { nanoid } from 'nanoid';
import { enqueueSnackbar } from 'notistack';
import { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { theme } from 'twin.macro';
import DocumentPlusCircleIcon from '../../../../assets/icons/DocumentPlusCircleIcon';
import {
  HedgeCropOptions,
  HedgeTypes,
} from '../../../../constants/risk-management';
import { useUser } from '../../../../contexts/auth-context';
import { useCreateHedge } from '../../../../hooks/hedge/use-hedge';
import { getYearOptions } from '../../../../utils';
import Button from '../../../commons/Button';
import CardHeader from '../../../commons/CardHeader';
import Select, { AwaitSelect } from '../../../commons/Select';
import ComboDatePicker from '../../../commons/inputs/ComboDatePicker';
import ComboSelectInput from '../../../commons/inputs/ComboSelectInput';
import TextInput from '../../../commons/inputs/TextInput';
import { getAddHedgePayload, PositionOptions, useHedgeForm } from '../helpers';

interface AddHedgeFormProps {
  onCancel: () => void;
  type: string;
}
export default function AddHedgeForm({ onCancel, type }: AddHedgeFormProps) {
  const user = useUser();

  const form = useForm({
    defaultValues: {
      producerShare: 100,
      uniqueKey: nanoid(),
      hedge_type: type,
      crop: 'Corn',
      month: moment().add(1, 'months').format('MMM'),
      year: moment().add(1, 'months').format('YY'),
    } as any,
  });
  const position = form.watch('position');

  const {
    basisOptions,
    strikeOptions,
    fieldOptions,
    isFetchingPrice,
    basisList,
    contractMonthsOptions,
    isFetchingContractMonths,
  } = useHedgeForm({ form, type });

  const { mutateAsync: createHedge, isPending } = useCreateHedge();
  const onSubmit = async ({ month, year, ...values }: any) => {
    const payload = getAddHedgePayload({
      formValues: values,
      userId: user?.id ?? '',
    });

    try {
      await createHedge(payload);
      enqueueSnackbar(`${position} Hedge ${type} successfully`, {
        variant: 'success',
      });
      onCancel();
      form.reset();
    } catch (error) {
      enqueueSnackbar(`Failed to ${position} Hedge ${type}`, {
        variant: 'error',
      });
    }
  };

  // Set price based on basis location
  const basisId = form.watch('basis_id');
  useEffect(() => {
    if (basisId) {
      const basis = basisList?.find((item) => item.basis_id === basisId);
      if (basis && HedgeTypes.Basis === type) {
        form.setValue('price', basis.basis);
      }
      if (basis && HedgeTypes.Cash === type) {
        form.setValue('price', basis.cashprice);
      }
    }
  }, [basisId, basisList, type]);

  return (
    <div className="space-y-8">
      <CardHeader
        icon={<DocumentPlusCircleIcon fill={theme`colors.base.000`} />}
        title={`Add Hedge ${type}`}
        tooltip={'tooltip'}
      />

      {/* Form */}
      <FormProvider {...form}>
        <div className="flex items-end gap-2">
          <Select
            label="Crop Year Hedged"
            name="crop"
            options={HedgeCropOptions}
            required
          />
          <Select
            name="crop_year"
            label="Crop Year"
            hiddenLabel
            options={getYearOptions(4, 1)}
            required
          />
        </div>

        <form className="space-y-4" onSubmit={form.handleSubmit(onSubmit)}>
          <Select
            label="Position"
            name="position"
            options={PositionOptions}
            required
          />

          <div className="grid grid-cols-2 gap-2">
            <AwaitSelect
              name="month"
              options={contractMonthsOptions}
              label="Contract Month/Year"
              required
              isLoading={isFetchingContractMonths}
            />
            <Select
              name="year"
              label="Year"
              hiddenLabel
              options={getYearOptions(10, 5, false)}
              required
            />
          </div>

          {[HedgeTypes.Basis, HedgeTypes.Cash].includes(type) && (
            <ComboSelectInput
              label="Basis Location"
              name="basis_id"
              options={basisOptions ?? []}
              required
            />
          )}

          {[HedgeTypes.Call, HedgeTypes.Put].includes(type) && (
            <ComboSelectInput
              label="Strike Price (cents/unit)"
              name="strike"
              required
              options={strikeOptions}
            />
          )}
          <ComboDatePicker label="Trade Date" name="dateTrade" required />

          <TextInput
            label={
              [HedgeTypes.Call, HedgeTypes.Put].includes(type)
                ? 'Option Premium'
                : 'Price'
            }
            name="price"
            required
            loading={isFetchingPrice}
            type="number"
          />

          <TextInput
            label="Quantity Hedged"
            name="quantity"
            type="number"
            required
          />

          <Select
            label="Apply to field"
            name="fieldApplied"
            options={[
              { label: 'None', value: 'None' },
              ...(fieldOptions ?? []),
            ]}
          />

          <div className="flex justify-between space-x-3">
            <Button className="w-full" onClick={onCancel}>
              Cancel
            </Button>
            <Button
              className="w-full"
              color="primary"
              type="submit"
              disabled={!form.formState.isValid || isPending}
              loading={isPending}
            >
              Apply
            </Button>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
