import { GridCellLayer } from 'deck.gl';
import { useEffect, useState } from 'react';
import * as turf from '@turf/turf';
import { filterWxData } from '../../utils';

export const useFieldsLayers = ({ wData }: { wData: any }) => {
  const [layers, setLayers] = useState<any[]>([]);
  const [hoverInfo, setHoverInfo] = useState<any>();

  useEffect(() => {
    if (wData) {
      const dataChunks = wData.map((data: any) => {
        const length = data['lon'] ? data['lon'].length : 0;
        if (length === 0) return {};

        return {
          value: data['value'],
          lons: data['lon'],
          lats: data['lat'],
          elevs: data['elev2'].map((e: any) => 1),
          reds: data['r'],
          blues: data['b'],
          greens: data['g'],
          length: length,
          crop: data['crop'],
          field: data['field'],
        };
      });

      const layers = dataChunks.map(
        (chunk: any, chunkIndex: any) =>
          new GridCellLayer({
            id: `user-field-map-tab-${chunkIndex}`,
            data: chunk,
            cellSize: 10,
            extruded: false,
            // @ts-ignore
            getPosition: (
              object,
              {
                index,
                data,
                target,
              }: { index: number; data: any; target: number[] }
            ) => {
              target[0] = data.lons[index];
              target[1] = data.lats[index];
              target[2] = data.elevs[index];
              return target;
            },
            // @ts-ignore
            getFillColor: (object, { index, data, target }) => {
              // @ts-ignore
              target[0] = 66;
              // @ts-ignore
              target[1] = 245;
              // @ts-ignore
              target[2] = 111;
              return target;
            },

            pickable: true,
            onHover: (info) => setHoverInfo(info),
            opacity: 0.2,
          })
      );
      setLayers(layers);
    }
  }, [wData]);

  return {
    layers,
    hoverInfo,
  };
};
