import { enqueueSnackbar } from 'notistack';
import { useEffect, useMemo, useState } from 'react';
import { useYearFilter } from '../../../../contexts/app-filter-context';
import { useMainUser, useUser } from '../../../../contexts/auth-context';
import {
  usePrescriptionProduct,
  useUpdateFieldPrescription,
} from '../../../../hooks/field/use-field-prescription';
import {
  FieldPrescriptionRes,
  FieldPrescriptionWithProducts,
  PrescriptionProductRes,
} from '../../../../types';
import DisclosureContentLayout from '../../commons/DisclosureContentLayout';
import FlipLayout from '../../commons/FlipLayout';
import { getZone, getZonesWithProducts } from '../helpers';
import FertilizerForm from './FertilizerForm';
import FertilizerInfo from './FertilizerInfo';
import FertilizerList from './FertilizerList';

export type FertilizerType = {
  id: string;
  name: string;
};

export default function AddFertilizer({
  inputType,
  fertilizer,
  crop,
  presctiptions,
  loading,
}: {
  inputType: 'fertilizer' | 'micronutrients';
  fertilizer: FertilizerType;
  crop: string;
  presctiptions: FieldPrescriptionRes[];
  loading: boolean;
}) {
  const user = useUser();
  const mainUser = useMainUser();
  const year = useYearFilter();
  const { data: products, isFetching } = usePrescriptionProduct({
    type: inputType,
    year: year ?? '',
    crop,
    userId: user?.id || '',
  });
  const { mutateAsync: update, isPending: isUpdating } =
    useUpdateFieldPrescription();

  const [list, setList] = useState<FieldPrescriptionWithProducts[]>([]);
  const [selectedZone, setSelectedZone] = useState(
    () => presctiptions[0]?.agrivar_zone ?? ''
  );
  const [isEditing, setIsEditing] = useState(false);
  const [selectedProductByZone, setSelectedProductZone] = useState(
    list.reduce(
      (acc, item) => {
        acc[item.agrivar_zone] = {} as PrescriptionProductRes;
        return acc;
      },
      {} as Record<string, PrescriptionProductRes>
    )
  );

  useEffect(() => {
    setList(getZonesWithProducts(presctiptions, products, inputType));
  }, [presctiptions, products, inputType]);

  useEffect(() => {
    setSelectedProductZone((prev) => {
      return {
        ...prev,
        [selectedZone]:
          products?.find(
            (item) =>
              getZone(item.user_id_agrivar_zone) === selectedZone &&
              item.prescription_type === fertilizer.name.toLowerCase()
          ) || ({} as PrescriptionProductRes),
      };
    });
  }, [products, selectedZone, fertilizer.name]);

  const selectedZoneObj = useMemo(() => {
    return (
      list?.find((item) => item.agrivar_zone === selectedZone) ??
      ({} as FieldPrescriptionWithProducts)
    );
  }, [list, selectedZone]);

  const handleSelectFertilizer = (
    zone: string,
    fertilizer: PrescriptionProductRes
  ) => {
    setSelectedProductZone((prev) => ({
      ...prev,
      [zone]: fertilizer,
    }));
  };

  // Update by knob
  const handleUpdate = async (value: number, fertilizerShortName: string) => {
    if (!user?.id || !year || !crop || !selectedZoneObj || !mainUser) return;
    try {
      await update({
        user: mainUser,
        user_id_crop: `${user.id}_${year}-${crop}`,
        agrivar_zone: selectedZoneObj.agrivar_zone,
        key: `${fertilizerShortName}_rate`,
        field: selectedZoneObj.field,
        field_index: selectedZoneObj.field_index,
        user_id: user.id,
        year: year,
        value: value.toString(),
      });
      enqueueSnackbar('Updated successfully', {
        variant: 'success',
      });
    } catch (error) {
      enqueueSnackbar('Failed to update', { variant: 'error' });
      console.error('Failed to update', error);
    }
  };

  return (
    <div className="space-y-8">
      <DisclosureContentLayout
        left={
          <>
            {isEditing ? (
              <FertilizerForm
                key={`${selectedZone}-${selectedProductByZone[selectedZone]?.uniqueid}`}
                inputType={inputType}
                title={
                  <h4>
                    Selected Zone:{' '}
                    <span className="text-primary">{`Zone ${selectedZoneObj.agrivar_zone1}`}</span>
                  </h4>
                }
                edit
                setIsEditing={setIsEditing}
                selectedZone={selectedZoneObj}
                fertilizer={fertilizer}
                crop={crop}
                selectedProduct={selectedProductByZone[selectedZone]}
                zones={list}
              />
            ) : (
              <FlipLayout disabled>
                {({ side, setSide }) => (
                  <div>
                    {side === 'front' && (
                      <FertilizerInfo
                        key={JSON.stringify(
                          selectedProductByZone[selectedZone]
                        )}
                        onFlip={() => setSide('back')}
                        fertilizer={fertilizer}
                        selectedZone={selectedZoneObj}
                        onUpdate={handleUpdate}
                        loading={isUpdating || isFetching}
                        selectedProduct={selectedProductByZone[selectedZone]}
                      />
                    )}
                    {side === 'back' && (
                      <FertilizerForm
                        inputType={inputType}
                        fertilizer={fertilizer}
                        crop={crop}
                        selectedZone={selectedZoneObj}
                        setIsEditing={setIsEditing}
                        zones={list}
                        onFlip={() => setSide('front')}
                      />
                    )}
                  </div>
                )}
              </FlipLayout>
            )}
          </>
        }
        right={
          <FertilizerList
            inputType={inputType}
            crop={crop}
            fertilizer={fertilizer}
            data={list}
            selectedZone={selectedZoneObj}
            onSelect={setSelectedZone}
            loading={loading || isFetching}
            setIsEditing={setIsEditing}
            setSelectedProductByZone={handleSelectFertilizer}
            selectedProductByZone={selectedProductByZone}
          />
        }
      />
    </div>
  );
}
