import { useMemo } from 'react';
import { useYearFilter } from '../../../../contexts/app-filter-context';
import { useUser } from '../../../../contexts/auth-context';
import { usePrescriptionProduct } from '../../../../hooks/field/use-field-prescription';
import { convertToFloat } from '../../../../utils';
import DisclosureContentLayout from '../../commons/DisclosureContentLayout';
import SubtotalInfo from './SubtotalInfo';
import SubtotalList from './SubtotalList';
import { groupBy } from 'lodash';

export default function Subtotal({
  crop,
  fieldIndex,
}: {
  crop: string;
  fieldIndex: string;
}) {
  const user = useUser();
  const year = useYearFilter();
  const { data: seedVarieties, isFetching: isFetchingS } =
    usePrescriptionProduct({
      type: 'seed',
      year: year ?? '',
      crop,
      userId: user?.id || '',
    });

  const { data: fertilizers, isFetching: isFetchingF } = usePrescriptionProduct(
    {
      type: 'fertilizer',
      year: year ?? '',
      crop,
      userId: user?.id || '',
    }
  );

  const { data: micronutrients, isFetching: isFetchingM } =
    usePrescriptionProduct({
      type: 'micronutrients',
      year: year ?? '',
      crop,
      userId: user?.id || '',
    });

  const { data: chemicals, isFetching: isFetchingC } = usePrescriptionProduct({
    type: 'chemicals',
    year: year ?? '',
    crop,
    userId: user?.id || '',
  });

  const list = useMemo(() => {
    if (!seedVarieties || !fertilizers || !micronutrients || !chemicals)
      return [];

    const prescriptionData = [
      ...seedVarieties,
      ...fertilizers,
      ...micronutrients,
      ...chemicals,
    ].filter((d) => d.field_index === fieldIndex);

    const groupData = groupBy(
      prescriptionData,
      (item) => item.user_id_agrivar_zone
    );
    const list = prescriptionData
      .map((data) => {
        const match = data.user_id_agrivar_zone.match(/Zone: \d+/);
        const zone = match ? match[0] : '';

        return {
          zone: zone,
          acres:
            convertToFloat(data.area) /
            groupData[data.user_id_agrivar_zone].length,
          prescription: data.variety || data.product,
          prescriptionQuantity:
            ((convertToFloat(data.area) /
              groupData[data.user_id_agrivar_zone].length) *
              convertToFloat(data.seeding_rate)) /
            convertToFloat(data.seeds_per_unit),
          prescriptionCost: convertToFloat(data.total_cost),
        };
      })
      ?.sort((a, b) => a.zone.localeCompare(b.zone));
    return list;
  }, [seedVarieties, fertilizers, micronutrients, chemicals, fieldIndex]);

  return (
    <div className="space-y-8">
      <DisclosureContentLayout
        left={<SubtotalInfo />}
        right={
          <SubtotalList
            data={list ?? []}
            loading={isFetchingS || isFetchingF || isFetchingM || isFetchingC}
          />
        }
      />
    </div>
  );
}
