import { PlusCircleIcon } from '@heroicons/react/20/solid';
import { enqueueSnackbar } from 'notistack';
import { ReactNode, useEffect, useMemo } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { FertilizerType } from '.';
import { useYearFilter } from '../../../../contexts/app-filter-context';
import { useUser } from '../../../../contexts/auth-context';
import { useFertilizerProducts } from '../../../../hooks/fertilizer/use-fertilizer';
import {
  useCreatePrescriptionProduct,
  useUpdatePrescriptionProduct,
} from '../../../../hooks/field/use-field-prescription';
import {
  AddFertilizerPayload,
  FieldPrescriptionWithProducts,
  PrescriptionProductRes,
} from '../../../../types';
import Button from '../../../commons/Button';
import CardHeader from '../../../commons/CardHeader';
import Checkbox from '../../../commons/Checkbox';
import Select, { AwaitSelect } from '../../../commons/Select';
import TextInput from '../../../commons/inputs/TextInput';
import {
  calculateCostPerAcre,
  calculateTotalCost,
  calculateTotalUnit,
} from './helpers';
import ComboSelectInput from '../../../commons/inputs/ComboSelectInput';

const operationOptions = [
  {
    label: 'Pre-Plant',
    value: 'Pre-Plant',
  },
  {
    label: 'Plant',
    value: 'Plant',
  },
  {
    label: 'Post Plant',
    value: 'Post Plant',
  },
  {
    label: 'Chemigation',
    value: 'Chemigation',
  },
  {
    label: 'Other',
    value: 'Other',
  },
];

export default function FertilizerForm({
  inputType,
  title,
  fertilizer,
  crop,
  selectedZone,
  edit,
  setIsEditing,
  selectedProduct,
  zones,
  onFlip,
}: {
  inputType: 'fertilizer' | 'micronutrients';
  title?: string | ReactNode;
  fertilizer: FertilizerType;
  crop: string;
  selectedZone: FieldPrescriptionWithProducts;
  edit?: boolean;
  setIsEditing?: (value: boolean) => void;
  selectedProduct?: PrescriptionProductRes;
  zones: FieldPrescriptionWithProducts[];
  onFlip?: () => void;
}) {
  const DEFAULT_AMOUNT_IN_LBS =
    selectedZone?.[`recommended_${fertilizer.id.toLowerCase()}_rate`] ?? 0;
  const user = useUser();
  const year = useYearFilter();
  const fertilizerName = fertilizer.name.toLowerCase();

  const { mutateAsync: create, isPending: isCreating } =
    useCreatePrescriptionProduct();
  const { mutateAsync: update, isPending: isUpdating } =
    useUpdatePrescriptionProduct();

  const editDefaultValue = useMemo(() => {
    return {
      product: selectedProduct?.product,
      [`${fertilizerName}`]: Number(selectedProduct?.[fertilizerName]),
      [`percent_of_${fertilizerName}_need`]: Number(
        selectedProduct?.[`percent_of_${fertilizerName}_need`]
      ),
      [`percent_of_${fertilizerName}_product`]: Number(
        selectedProduct?.[`percent_of_${fertilizerName}_product`]
      ),
      unit_type: selectedProduct?.unit_type,
      price_per_unit: Number(selectedProduct?.price_per_unit),
      total_units: Number(selectedProduct?.total_units),
      total_cost: Number(selectedProduct?.total_cost),
      application_time: selectedProduct?.application_time,
    };
  }, [fertilizerName, selectedProduct]);

  const form = useForm({
    defaultValues: edit
      ? editDefaultValue
      : {
          product: '',
          [`${fertilizerName}`]:
            selectedZone?.[`recommended_${fertilizer.id.toLowerCase()}_rate`],
          [`percent_of_${fertilizerName}_need`]: 100,
          [`percent_of_${fertilizerName}_product`]: 0,
          unit_type: '',
          price_per_unit: 0,
          total_units: 0,
          total_cost: 0,
          application_time: '',
        },
  });

  const handleCreate = async ({
    total_cost,
    total_units,
    cost_per_acre,
    applyAllZones,
    ...values
  }: any) => {
    if (!year || !user) return;
    const appliedZones = form.watch('applyAllZones')
      ? zones.map((zone) => {
          return {
            zone: zone.agrivar_zone,
            acres: zone.area,
            total_cost: calculateTotalCost({
              amount: Number(values[`${fertilizerName}`]),
              percentNeed: Number(values[`percent_of_${fertilizerName}_need`]),
              percentInProduct: Number(
                values[`percent_of_${fertilizerName}_product`]
              ),
              pricePerUnit: Number(values.price_per_unit),
              numberOfAcres: parseFloat(zone.area),
              unit: values.unit_type,
            }).toString(),
            total_units: total_units.toString(),
            cost_per_acre: cost_per_acre.toString(),
          };
        })
      : [
          {
            zone: selectedZone.agrivar_zone,
            acres: selectedZone.area,
            total_cost: total_cost.toString(),
            total_units: total_units.toString(),
            cost_per_acre: cost_per_acre.toString(),
          },
        ];
    const payload: AddFertilizerPayload = {
      ...values,
      ...Object.fromEntries(
        Object.entries(values).map(([key, value]) => [key, String(value)])
      ),
      zone: appliedZones,
      crop: crop,
      year: year,
      userId: user.id,
      input_type: inputType,
      field_index: selectedZone.field_index,
      field: selectedZone.field,
      prescription_type: fertilizer.name.toLowerCase(),
    };

    try {
      await create(payload);
      setIsEditing?.(false);
      enqueueSnackbar('Fertilizer added successfully', { variant: 'success' });
      onFlip?.();
    } catch (error) {
      enqueueSnackbar('Failed to add fertilizer', { variant: 'error' });
      console.error(error);
    }
  };

  const handleUpdate = async (values: any) => {
    if (!selectedProduct) return;
    try {
      await update({
        ...selectedProduct,
        ...Object.fromEntries(
          Object.entries(values).map(([key, value]) => [key, String(value)])
        ),
        input_type: inputType,
        prescription_type: fertilizer.name.toLowerCase(),
        area: selectedZone.area,
        user_id: `${user?.id}_${year}`,
        user_id_agrivar_zone: `${user?.id}_${year}-${selectedZone.agrivar_zone}`,
        crop: crop,
        field: selectedZone.field,
        field_index: selectedZone.field_index,
      });
      setIsEditing?.(false);
      enqueueSnackbar('Updated successfully', {
        variant: 'success',
      });
      onFlip?.();
    } catch (error) {
      enqueueSnackbar('Something went wrong, please try again later', {
        variant: 'error',
      });
    }
  };

  const { data: products, isFetching } = useFertilizerProducts(
    user?.network_partner || ''
  );
  const productOptions = useMemo(() => {
    return products
      ?.filter((item) => {
        const pctInProduct = Number(
          item?.[`${fertilizer.id.toLowerCase()}_pct`]
        );
        return Number(pctInProduct) > 0;
      })
      ?.map((item) => ({
        label: item.itemdescription,
        value: item.itemdescription,
      }));
  }, [fertilizer.id, products]);

  const pricePerUnit = form.watch('price_per_unit');
  const product = form.watch('product');
  const productData = useMemo(
    () => products?.find((item) => item.itemdescription === product),
    [product, products]
  );

  // set unit type, price per unit and percent of product
  useEffect(() => {
    if (productData) {
      form.setValue('unit_type', productData.reportinguom);
      form.setValue(
        `percent_of_${fertilizerName}_product`,
        productData[`${fertilizer.id.toLowerCase()}_pct`] ?? 0
      );
      form.setValue('price_per_unit', Number(productData.price));
    }
  }, [fertilizer.id, fertilizerName, productData]);

  const area = parseFloat(selectedZone.area);
  const amountInLbs = Number(form.watch(`${fertilizerName}`));
  const percentNeed = Number(form.watch(`percent_of_${fertilizerName}_need`));
  const percentInProduct = Number(
    form.watch(`percent_of_${fertilizerName}_product`)
  );
  const unitType = form.watch('unit_type');
  const totalUnits = calculateTotalUnit({
    amount: amountInLbs,
    percentNeed: percentNeed,
    percentInProduct: percentInProduct,
  });
  const totalCost = calculateTotalCost({
    amount: amountInLbs,
    percentNeed: percentNeed,
    percentInProduct: percentInProduct,
    pricePerUnit: pricePerUnit,
    numberOfAcres: area,
    unit: unitType ?? 'LBS',
  });

  const costPerAcre = calculateCostPerAcre({
    amount: amountInLbs,
    percentNeed: percentNeed,
    percentInProduct: percentInProduct,
    pricePerUnit: pricePerUnit,
    unit: unitType ?? 'LBS',
  });

  useEffect(() => {
    form.setValue('total_cost', totalCost);
    form.setValue('total_units', totalUnits);
    form.setValue('cost_per_acre', costPerAcre);
  }, [form, totalCost, totalUnits]);

  // // set amount in lbs based on percent need
  // useEffect(() => {
  //   const amount = DEFAULT_AMOUNT_IN_LBS * (percentNeed / 100);
  //   form.setValue(`${fertilizerName}`, amount);
  // }, [percentNeed]);

  return (
    <div className="space-y-8">
      <CardHeader
        title={title ?? `${fertilizer.name} (lbs. Per Acre)`}
        tooltip={'tooltip'}
      />
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(edit ? handleUpdate : handleCreate)}
          className="space-y-4"
        >
          <ComboSelectInput
            label="Select Product"
            name="product"
            options={productOptions}
            required
          />
          <TextInput
            name={`${fertilizerName}`}
            label={`${fertilizer.name} Amount In Pounds`}
            type="number"
            placeholder="Please enter amount"
          />
          <TextInput
            name={`percent_of_${fertilizerName}_need`}
            label={`Percent Of ${fertilizer.name} Need`}
            type="number"
            placeholder="Please enter percent"
          />
          <TextInput
            name={`percent_of_${fertilizerName}_product`}
            label={`Percent Of ${fertilizer.name} In Product`}
            type="number"
          />
          <Select
            label="Select Unit Type"
            name="unit_type"
            options={[
              { label: 'LBS', value: 'LBS' },
              { label: 'TONS', value: 'TONS' },
              { label: 'GAL', value: 'GAL' },
            ]}
          />
          <TextInput
            name="price_per_unit"
            label="Price Per Unit"
            type="number"
            placeholder="Please enter price per unit"
          />
          <TextInput name="total_units" label="Total Units" type="number" />
          <TextInput type="number" name="cost_per_acre" label="Cost Per Acre" />
          <TextInput name="total_cost" label="Total Cost" type="number" />
          <Select
            name="application_time"
            label="Select Application Period"
            options={operationOptions}
          />

          {!edit && (
            <>
              <Checkbox name="applyAllZones" label="Apply to all Zones" />
              <div className="flex space-x-4">
                <Button disabled={isUpdating} onClick={() => onFlip?.()}>
                  Cancel
                </Button>
                <Button
                  type="submit"
                  color="primary"
                  startIcon={<PlusCircleIcon className="size-6" />}
                  shadow
                  disabled={isCreating || !form.formState.isValid}
                  loading={isCreating}
                >
                  Add To Fertilizers
                </Button>
              </div>
            </>
          )}
          {edit && (
            <div className="flex space-x-4">
              <Button
                disabled={isUpdating}
                onClick={() => setIsEditing?.(false)}
              >
                Cancel
              </Button>
              <Button
                type="submit"
                color="primary"
                disabled={isUpdating || !form.formState.isValid}
                loading={isUpdating}
              >
                Update
              </Button>
            </div>
          )}
        </form>
      </FormProvider>
    </div>
  );
}
