import apiClient from './api-client';

export const getInvoicesApi = ({
  userId,
  partner,
}: {
  userId: string;
  partner: string;
}) => {
  return apiClient.get(`/invoices?user_id=${userId}&partner=${partner}`);
};
