import { Field, Label } from '@headlessui/react';
import clsx from 'clsx';
import { nanoid } from 'nanoid';
import { enqueueSnackbar } from 'notistack';
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import BillIcon from '../../../assets/icons/BillIcon';
import BillPaidIcon from '../../../assets/icons/BillPaidIcon';
import DollarLockIcon from '../../../assets/icons/DollarLockIcon';
import DollarUnlockIcon from '../../../assets/icons/DollarUnlockIcon';
import FarmIcon from '../../../assets/icons/FarmIcon';
import FieldIcon from '../../../assets/icons/FieldIcon';
import FileUploadIcon from '../../../assets/icons/FileUploadIcon';
import FormEntryIcon from '../../../assets/icons/FormEntryIcon';
import PhotoUploadIcon from '../../../assets/icons/PhotoUploadIcon';
import SproutIcon from '../../../assets/icons/SproutIcon';
import { SupportedCrops, TextractType } from '../../../constants';
import { useYearFilter } from '../../../contexts/app-filter-context';
import { useUser } from '../../../contexts/auth-context';
import { useCreateExpense } from '../../../hooks/expense/use-expense';
import { useFieldPolygonsByYear } from '../../../hooks/field/use-field-polygons';
import { useFieldPrescription } from '../../../hooks/field/use-field-prescription';
import { useTextract } from '../../../hooks/textract/use-textract';
import { uploadFile } from '../../../services/awsS3';
import Button from '../../commons/Button';
import CardHeader from '../../commons/CardHeader';
import ComboDatePicker from '../../commons/inputs/ComboDatePicker';
import ComboSelectInput from '../../commons/inputs/ComboSelectInput';
import RadioInput from '../../commons/inputs/RadioInput';
import TextInput from '../../commons/inputs/TextInput';
import Select, { AwaitSelect } from '../../commons/Select';

const SubmitOptions = [
  {
    value: 'formEntry',
    label: 'Form Entry',
    icon: FormEntryIcon,
  },
  // {
  //   value: 'uploadFile',
  //   label: 'Upload File',
  //   icon: FileUploadIcon,
  // },
  // {
  //   value: 'uploadPhoto',
  //   label: 'Upload Photo',
  //   icon: PhotoUploadIcon,
  // },
];

const ExpenseTypes = [
  {
    value: 'direct',
    label: 'Direct Expense',
    icon: DollarUnlockIcon,
  },
  {
    value: 'fixed',
    label: 'Fixed Expense',
    icon: DollarLockIcon,
  },
];

const AppliedTo = [
  {
    value: 'whole',
    label: 'Whole Farm',
    icon: FarmIcon,
  },
  {
    value: 'enterprise',
    label: 'Crop Enterprise',
    icon: SproutIcon,
  },
  {
    value: 'field',
    label: 'Individual Field',
    icon: FieldIcon,
  },
];

export default function AddFarmExpenseForm() {
  const user = useUser();
  const year = useYearFilter();
  const form = useForm({ mode: 'onBlur', reValidateMode: 'onChange' });
  const [step, setStep] = useState('first');

  const submitOption = form.watch('submitOption');
  const expenseType = form.watch('expense_type');
  const expenseAppliedTo = form.watch('expense_applied_to');
  const crop = form.watch('crop');
  const expenseField = form.watch('expenseField');
  const expenseZone = form.watch('expenseZone');
  const cropOther2 = form.watch('cropOther2');

  const handleFirstStep = () => {
    if (submitOption === 'formEntry') {
      setStep('expenseType');
      return;
    }
  };

  // Upload file
  const [fileUpload, setFileUpload] = useState({
    fileName: '',
    loading: false,
  });
  const [isTextracting, setIsTextracting] = useState(false);
  const uploading = fileUpload?.loading || isTextracting;

  // Textract
  const { mutateAsync: getTextract, data } = useTextract();
  useEffect(() => {
    (async () => {
      try {
        if (fileUpload.fileName && !isTextracting) {
          await getTextract({
            documentName: fileUpload.fileName,
            type: TextractType.EXPENSE,
          });
          setIsTextracting(false);
        }
      } catch (error) {
        console.error('Error getting Textract data:', error);
      }
    })();
  }, [data, fileUpload.fileName, isTextracting]);

  // Reference to the file input element
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleUploadFile = (e: ChangeEvent<HTMLInputElement>) => {
    const getResponse = (response: any) => {
      const { key: fileName } = response || {};
      setFileUpload({ fileName, loading: false });
      // Reset the file input value
      if (fileInputRef.current) {
        fileInputRef.current.value = '';
      }
    };

    const file = e.target.files?.[0];
    if (file) {
      const type = file.type.split('/')[1];
      const timeStamp = Date.now();
      const defaultFileName = `${user?.id}-${nanoid()}-${timeStamp}.${type}`;

      setFileUpload({ ...fileUpload, loading: true });
      uploadFile(file, defaultFileName, type, getResponse);
    }
  };

  // Field options
  const { data: allFields } = useFieldPolygonsByYear(
    user?.id ?? '',
    year ?? ''
  );
  const fieldOptions = useMemo(() => {
    return allFields?.map((field) => ({
      label: field.field,
      value: `${field.field}_${field.field_index}`,
    }));
  }, [allFields]);
  // Zone options
  const [field, fieldIndex] = expenseField?.split('_') || [];
  const { data: fieldZones } = useFieldPrescription(
    `${user?.id}_${year}`,
    fieldIndex
  );
  const zoneOptions =
    fieldZones
      ?.sort(
        (fieldA, fiedlB) =>
          Number.parseInt(fieldA.agrivar_zone1) -
          Number.parseInt(fiedlB.agrivar_zone1)
      )
      ?.map((field) => ({
        value: field.agrivar_zone,
        label: field.agrivar_zone,
      })) || [];
  zoneOptions.unshift({ value: 'all zones', label: 'All Zones' });

  // Handle form submission
  const totalPage = 1;
  const [remainPages, setRemainPages] = useState<number[]>(
    Array.from({ length: totalPage }, (_, i) => i + 1)
  );
  const [currentPage, setCurrentPage] = useState(1);
  const { mutateAsync: createExpense, isPending } = useCreateExpense();
  const expenseAppliedToPayload = useMemo(() => {
    switch (expenseAppliedTo) {
      case 'whole':
        return 'whole';
      case 'enterprise':
        return crop;
      case 'field':
        return `${field}-${expenseZone}`;
      default:
        return '';
    }
  }, [crop, expenseAppliedTo, expenseZone, field]);

  // Submit one expense when multiple pages
  // Using the error handler of react-hook-form
  // this only called when the form is not valid
  // so able to submit the expense even other pages are not filled
  // use this to avail the validation of react-hook-form
  const handleSubmitOne = async (errors: any) => {
    if (!user || !year) return;

    if (errors[`page${currentPage}`]) {
      enqueueSnackbar(
        'Please complete required informations for this expense',
        {
          variant: 'warning',
        }
      );
      return;
    }

    const currentPageValues = form.getValues()[`page${currentPage}`];
    try {
      await createExpense([
        {
          ...currentPageValues,
          expenseType,
          expenseField: fieldIndex ?? '',
          expenseZone: expenseZone ?? '',
          cropOther2: cropOther2 ?? '',
          expenseAppliedTo: expenseAppliedToPayload,
          userId: `${user.id}_${year}`,
        },
      ]);

      setRemainPages(remainPages.filter((page) => page !== currentPage));

      // Move to next page
      if (currentPage < totalPage) {
        setCurrentPage(currentPage + 1);
      } else if (remainPages.length === 1) {
        setCurrentPage(remainPages[0]);
      }
    } catch (error) {}
  };

  // Submit all expenses and single expense (if only one page)
  // Using the handleSubmit of react-hook-form
  // This only called when all pages are filled/form is valid
  const handleSubmit = async (values: any) => {
    if (!user || !year) return;

    const payload = remainPages.map((page) => {
      const pageValues = values[`page${page}`];
      return {
        ...pageValues,
        expenseType,
        expenseField: fieldIndex ?? '',
        expenseZone: expenseZone ?? '',
        cropOther2: cropOther2 ?? '',
        expenseAppliedTo: expenseAppliedToPayload,
        userId: `${user.id}_${year}`,
      };
    });

    try {
      await createExpense(payload);
      enqueueSnackbar('All expenses submitted successfully', {
        variant: 'success',
      });
      setRemainPages([]);
    } catch (error) {
      enqueueSnackbar('Failed to submit expense', { variant: 'error' });
    }
  };

  useEffect(() => {
    if (remainPages.length === 0) {
      form.reset();
      setStep('first');
      setCurrentPage(1);
      setRemainPages(Array.from({ length: totalPage }, (_, i) => i + 1));
    }
  }, [remainPages.length]);

  useEffect(() => {
    setRemainPages(Array.from({ length: totalPage }, (_, i) => i + 1));
  }, [totalPage]);

  const isSubmitted = !remainPages.includes(currentPage);

  return (
    <div className="space-y-6">
      <CardHeader
        title="Add New Farm Expenses"
        subtitle={
          step === 'details' && totalPage > 1
            ? `Page ${currentPage} / ${totalPage}`
            : ''
        }
      />
      <FormProvider {...form}>
        <form
          onSubmit={form.handleSubmit(
            (values) => handleSubmit(values),
            (errors) => handleSubmitOne(errors)
          )}
        >
          {/* First Step */}
          <div className="space-y-5" hidden={step !== 'first'}>
            <p>How would you like to submit the expense</p>
            {SubmitOptions.map(({ value, label, icon: Icon }) => (
              <RadioInput
                key={value}
                name="submitOption"
                value={value}
                label={label}
                icon={Icon}
                labelClassName="text-base-800"
              />
            ))}
            {submitOption === 'formEntry' && (
              <Button color="primary" onClick={handleFirstStep}>
                Next
              </Button>
            )}
            {fileUpload.fileName}
            {(submitOption === 'uploadFile' ||
              submitOption === 'uploadPhoto') && (
              <Button color="primary" loading={uploading} className="relative">
                <input
                  ref={fileInputRef}
                  type="file"
                  name="file"
                  className="absolute top-0 left-0 opacity-0 w-full h-full cursor-pointer"
                  onChange={handleUploadFile}
                  disabled={uploading}
                />
                Upload
              </Button>
            )}
          </div>
          {/* expense_type Step */}
          <div className="space-y-5" hidden={step !== 'expenseType'}>
            <p>Please choose expense type.</p>
            {ExpenseTypes.map(({ value, label, icon: Icon }) => (
              <RadioInput
                key={value}
                name="expense_type"
                value={value}
                label={label}
                icon={Icon}
                labelClassName="text-base-800 hover:text-primary"
              />
            ))}
            <div className="flex gap-4">
              <Button onClick={() => setStep('first')}>Back</Button>
              <Button
                color="primary"
                disabled={!expenseType}
                onClick={() => setStep('expenseAppliedTo')}
              >
                Next
              </Button>
            </div>
          </div>
          {/* expense_applied_to Step */}
          <div className="space-y-5" hidden={step !== 'expenseAppliedTo'}>
            <p>What should the expense apply to?</p>
            {AppliedTo.map(({ value, label, icon: Icon }) => (
              <RadioInput
                key={value}
                name="expense_applied_to"
                value={value}
                label={label}
                icon={Icon}
                labelClassName="text-base-800 hover:text-primary"
              />
            ))}
            <div className="flex gap-4">
              <Button onClick={() => setStep('expenseType')}>Back</Button>
              <Button
                color="primary"
                disabled={!expenseAppliedTo}
                onClick={() => {
                  setStep(
                    expenseAppliedTo === 'whole' ? 'details' : expenseAppliedTo
                  );
                }}
              >
                Next
              </Button>
            </div>
          </div>
          {/* Crop enterprise step */}
          <div className="space-y-5" hidden={step !== 'enterprise'}>
            <p>Which crop should the expense apply to?</p>
            <Select
              label="Crop"
              name="crop"
              options={[
                ...SupportedCrops.map((crop) => ({
                  label: crop,
                  value: crop.toLowerCase(),
                })),
                { value: 'other', label: 'Other' },
              ]}
            />
            {crop === 'other' && (
              <TextInput
                name="cropOther2"
                label="Describe Other"
                placeholder="Describe Other"
              />
            )}

            <div className="flex gap-4">
              <Button onClick={() => setStep('expenseAppliedTo')}>Back</Button>
              <Button
                color="primary"
                disabled={!crop}
                onClick={() => setStep('details')}
              >
                Next
              </Button>
            </div>
          </div>
          {/* Individual field step */}
          <div className="space-y-5" hidden={step !== 'field'}>
            <p>Which field should the expense apply to?</p>
            <AwaitSelect
              label="Field"
              name="expenseField"
              options={fieldOptions || []}
              isLoading={false}
            />

            {expenseField && (
              <AwaitSelect
                label="Zone"
                name="expenseZone"
                options={zoneOptions || []}
                isLoading={false}
              />
            )}
            <div className="flex gap-4">
              <Button onClick={() => setStep('expenseAppliedTo')}>Back</Button>
              <Button
                color="primary"
                disabled={!expenseField && !expenseZone}
                onClick={() => setStep('details')}
              >
                Next
              </Button>
            </div>
          </div>
          {/* Details step */}

          <div
            className={clsx({
              hidden: step !== 'details',
            })}
          >
            {Array.from({ length: totalPage }).map((_, index) => (
              <fieldset
                key={`page${index + 1}`}
                className={clsx('space-y-5', {
                  hidden: currentPage !== index + 1,
                })}
              >
                <p className="text-secondary">* Indicates Required Field</p>
                <TextInput
                  name={`page${index + 1}.category`}
                  label="Category"
                  required
                  disabled={isSubmitted}
                />
                <ComboDatePicker
                  name={`page${index + 1}.purchaseDate`}
                  label="Date of Expense"
                  required
                  options={[
                    {
                      label: '2021-01-01',
                      value: '2021-01-01',
                    },
                    {
                      label: '2021-01-02',
                      value: '2021-01-02',
                    },
                    {
                      label: '2021-01-03',
                      value: '2021-01-03',
                    },
                    {
                      label: '2021-01-04',
                      value: '2021-01-04',
                    },
                    {
                      label: '2021-01-05',
                      value: '2021-01-05',
                    },
                  ]}
                  disabled={isSubmitted}
                />
                <ComboSelectInput
                  name={`page${index + 1}.vendor`}
                  label="Vendor"
                  options={[
                    {
                      label: 'Vendor 1',
                      value: 'vendor1',
                    },
                    {
                      label: 'Vendor 2',
                      value: 'vendor2',
                    },
                    {
                      label: 'Vendor 3',
                      value: 'vendor3',
                    },
                  ]}
                  required
                  disabled={isSubmitted}
                />
                <ComboSelectInput
                  name={`page${index + 1}.vendorAddress`}
                  label="Vendor Address"
                  options={[]}
                  required
                  disabled={isSubmitted}
                />
                <TextInput
                  name={`page${index + 1}.description`}
                  label="Description"
                  disabled={isSubmitted}
                />
                <TextInput
                  name={`page${index + 1}.expenseAmount`}
                  type="number"
                  label="Expense Amount"
                  required
                  disabled={isSubmitted}
                />
                {/* <Field className="space-y-2">
                  <Label>Status</Label>
                  <div className="space-y-4">
                    <RadioInput
                      name={`page${index + 1}.isPaid`}
                      value="true"
                      label="Expense Paid"
                      icon={BillPaidIcon}
                      labelClassName="text-base-800"
                      disabled={isSubmitted}
                    />
                    <RadioInput
                      name={`page${index + 1}.isPaid`}
                      value="false"
                      label="Expense Unpaid"
                      icon={BillIcon}
                      labelClassName="text-base-800"
                      disabled={isSubmitted}
                    />
                  </div>
                </Field> */}
                <ComboDatePicker
                  name={`page${index + 1}.entryDate`}
                  label="Expense Entry Date"
                  required
                  options={[]}
                  disabled={isSubmitted}
                />
              </fieldset>
            ))}

            <div className="space-y-5 mt-5">
              {totalPage > 1 && (
                <div className="flex space-x-4">
                  <Button
                    type="button"
                    disabled={currentPage === 1}
                    onClick={() => setCurrentPage(currentPage - 1)}
                  >
                    Previous
                  </Button>
                  <div className="whitespace-nowrap rounded-lg bg-base-1000 flex justify-center items-center px-4 space-x-1">
                    <p>{currentPage} /</p>
                    <p className="text-secondary">{totalPage}</p>
                  </div>
                  <Button
                    type="button"
                    disabled={currentPage >= totalPage}
                    onClick={() => setCurrentPage(currentPage + 1)}
                  >
                    Next
                  </Button>
                </div>
              )}

              <div className="flex space-x-4">
                {totalPage <= 1 && (
                  <Button
                    onClick={() => {
                      form.reset();
                      setStep('first');
                    }}
                  >
                    Cancel
                  </Button>
                )}
                {totalPage <= 1 && (
                  <Button
                    color="primary"
                    type="submit"
                    disabled={
                      isSubmitted || isPending || !form.formState.isValid
                    }
                    loading={isPending}
                  >
                    Submit Expense
                  </Button>
                )}
                {totalPage > 1 && (
                  <Button
                    color="primary"
                    type="submit"
                    disabled={isSubmitted || isPending}
                    loading={isPending}
                  >
                    {remainPages.includes(currentPage)
                      ? 'Submit This'
                      : 'Submitted'}
                  </Button>
                )}
                {totalPage > 1 && (
                  <Button
                    color="primary"
                    onClick={form.handleSubmit(handleSubmit)}
                    disabled={
                      !remainPages.length ||
                      isPending ||
                      !form.formState.isValid
                    }
                    loading={isPending}
                  >
                    Submit All
                  </Button>
                )}
              </div>

              {totalPage > 1 && (
                <Button
                  disabled={!expenseField && !expenseZone}
                  onClick={() => setStep('details')}
                >
                  Save Expense
                </Button>
              )}
              <p>
                You may save the expense if you would prefer to enter them
                another time.
              </p>
            </div>
          </div>
        </form>
      </FormProvider>
    </div>
  );
}
