import { useQuery } from '@tanstack/react-query';
import { getInvoicesApi } from '../../api/invoice-api';
import { InvoiceItem } from '../../types/invoice';

export const useGetInvoices = ({
  userId,
  partner,
}: {
  userId: string;
  partner: string;
}) => {
  const { data, ...rest } = useQuery({
    queryKey: ['invoices', userId, partner],
    queryFn: () => getInvoicesApi({ userId, partner }),
    enabled: !!userId && !!partner,
  });
  return { data: data?.data as InvoiceItem[], ...rest };
};
