import { useQuery } from '@tanstack/react-query';
import { getOptionsApi, getStrikePricesApi } from '../../api/market-api';

export const useOptions = (symbol: string, option?: string) => {
  const res = useQuery({
    queryKey: ['market/options', symbol, option ?? ''],
    queryFn: () =>
      option ? getOptionsApi(symbol, option) : getOptionsApi(symbol),
    enabled: !!symbol,
  });
  return { data: res.data, isLoading: res.isLoading };
};

export const useStrikePrices = (symbol: string) => {
  const res = useQuery({
    queryKey: ['market/strikePrices', symbol],
    queryFn: () => getStrikePricesApi(symbol),
    enabled: !!symbol,
  });

  // res.data has 'Put' and 'Call' keys - sort each list value by its parseInt(item.value)
  if (res.data?.strikePrices) {
    res.data.strikePrices['Put'] = res.data?.strikePrices['Put']?.sort(
      (a: any, b: any) => parseInt(a.value) - parseInt(b.value)
    );
    res.data.strikePrices['Call'] = res.data?.strikePrices['Call']?.sort(
      (a: any, b: any) => parseInt(a.value) - parseInt(b.value)
    );
  }
  return { data: res.data };
};
